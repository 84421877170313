import moment from "moment";

export const getArrayByField = (array: any[], field: string) => {
  return array?.map((item) => item?.[field])
}

export const getTimezoneOffset = () => {
  const currentDate = new Date();
  const offsetHours = -currentDate.getTimezoneOffset() / 60;

  return offsetHours
}

export const isValidDateFormat = (dateString: string) => {
  const format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  return moment(dateString, format, true).isValid();
}

