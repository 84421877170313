/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

// import RouterExample from '../modules/example/RouterExample';
import RouterDashboard from '../modules/dashboard/RouterDashboard';
import RouterMaster from '../modules/master/RouterMaster';
import RouterAssemblyDisassembly from '../modules/assembly-disassembly/RouterAssemblyDisassembly';
import RouterAsn from '../modules/asn/RouterAsn';
import RouterWarehouse from '../modules/warehouse/RouterWarehouse';
import RouterReceiving from '../modules/receiving/RouterReceiving';
import RouterPlanning from '../modules/planning/RouterPlanning';
import RouterReservation from '../modules/reservation/RouterReservation';
import RouterPutAway from '../modules/putaway/RouterPutAway';
import RouterInventoryTransfer from '../modules/inventory-transfer/RouterInventoryTransfer';
import RouterPicking from '../modules/picking/RouterPicking';
import RouterReport from '../modules/report/RouterReport';
import RouterInventory from '../modules/inventory-analyzer/RouterInventoryAnalyzer';
import RouterPacking from '../modules/packing/RouterPacking';
import RouterGoodsIssue from '../modules/goods-issue/RouterGoodsIssue';
import RouterInventoryAdjutsment from '../modules/inventory-adjustment/RouterInventoryAdjutsment';
import RouterInventoryReplenishment from "../modules/inventory-replenishment/RouterInventoryReplenishment";
import RouterCycleCount from '../modules/cycle-count/RouteCycleCount';
import RouterTraceAndTrack from '../modules/trace-and-track/RouterTraceAndTrack';

export const RouterContainer = [
  // ...RouterExample,
  ...RouterTraceAndTrack,
  ...RouterDashboard,
  ...RouterMaster,
  ...RouterAssemblyDisassembly,
  ...RouterAsn,
  ...RouterWarehouse,
  ...RouterReceiving,
  ...RouterPlanning,
  ...RouterReservation,
  ...RouterPutAway,
  ...RouterInventoryTransfer,
  ...RouterPicking,
  ...RouterReport,
  ...RouterInventory,
  ...RouterPacking,
  ...RouterGoodsIssue,
  ...RouterInventoryAdjutsment,
  ...RouterCycleCount,
  ...RouterInventoryReplenishment,
];

export const RouterNonContainer = [
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: React.lazy(() => import('../modules/auth/views/LoginAuth')),
  },
  {
    path: '/singlesignon/:token',
    exact: true,
    name: 'Single Sign On',
    component: React.lazy(() => import('../modules/auth/views/SingleSignOnAuth')),
  },
  // {
  //   path: '/register',
  //   exact: true,
  //   name: 'Register',
  //   component: React.lazy(() => import('../modules/example/views/Pages/Register/Register')),
  // },
  {
    path: '/internal/:username',
    exact: true,
    name: 'Internal',
    component: React.lazy(() => import('../modules/auth/views/Internal')),
  },
  {
    path: '/internal',
    exact: true,
    name: 'Internal',
    component: React.lazy(() => import('../modules/auth/views/Internal')),
  },
  {
    path: '/external/:username',
    exact: true,
    name: 'External',
    component: React.lazy(() => import('../modules/auth/views/External')),
  },
  {
    path: '/external',
    exact: true,
    name: 'External',
    component: React.lazy(() => import('../modules/auth/views/External')),
  },
  {
    path: '/404',
    exact: true,
    name: '404 Not Found',
    component: React.lazy(() => import('../modules/example/views/Pages/Page404/Page404')),
  },
  {
    path: '/500',
    exact: true,
    name: '500 Internal Server Error',
    component: React.lazy(() => import('../modules/example/views/Pages/Page500/Page500')),
  },
];
