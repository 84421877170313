import SparkMD5 from 'spark-md5';
const chunkSize = 1 * 1024 * 1024 * 15; // The size of each chunk, set to 15 Megabyte
// Use the Blob.slice method to split the file.
// This method is also used differently in different browsers.
const blobSlice = File.prototype.slice;

export const getPager = (totalItems: number, currentPage: number, pageSize: number, direction: string, orderBy: string) => {
  try {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 3 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
      direction: direction,
      orderBy: orderBy
    };
  } catch (e) {
    console.log('error at getPager: ', e);
  }
};

export const cekDataApi = (respons: any, masterTypeId: string) => {
  let isValid = true;
  let errormessage = "";
  if (respons.status !== 200) {
    isValid = false;
    errormessage = respons.data.message;
    if (respons.status === 401) {
      errormessage = `Expired`;
    }
  }

  if (respons.data.statusCode !== 200) {
    isValid = false;
    errormessage = respons.data.message;
    if (respons.data.statusCode === 401) {
      errormessage = `Expired`;
    }
  }
  return {
    isValid,
    errormessage,
  }
}

export const simpleSortList = (list: any, order: string, property: string) => {
  let direction: number;
  if (order === 'ASC') {
    direction = 1;
  } else {
    direction = -1;
  }
  list.sort((a: any, b: any) => {
    let comparison = 0;

    if (a[property] < b[property]) return -1 * direction;
    if (a[property] > b[property]) return 1 * direction;

    if (direction === 1) {
      comparison = parseInt(a[property]) - parseInt(b[property]);
    } else {
      comparison = parseInt(b[property]) - parseInt(a[property]);
    }

    return comparison;
  });
  return list;
};

export const sortList = (list: any, order: string, property: string) => {
  let direction: number;
  if (order === 'ASC') {
    direction = 1;
  } else {
    direction = -1;
  }
  const regex = /^([a-z]*)(\d*)/i;
  list.sort((a: any, b: any) => {
    let _a = a[property].match(regex);
    let _b = b[property].match(regex);

    let comparison = 0;

    if (property !== 'receiptDate' && property !== 'expiredDate') {
      if (_a[1] < _b[1]) return comparison = -1 * direction;
      if (_a[1] > _b[1]) return comparison = 1 * direction;
    } else {
      if (a[property] < b[property]) return comparison = -1 * direction;
      if (a[property] > b[property]) return comparison = 1 * direction;
    }

    if (direction === 1) {
      comparison = parseInt(_a[2]) - parseInt(_b[2]);
    } else {
      comparison = parseInt(_b[2]) - parseInt(_a[2]);
    }

    return comparison;
  });
  return list;
};

export const sortListNormal = (list: any, order: string, property: string) => {
  // console.log(list, order, property);
  let direction: number;
  if (order === 'ASC') {
    direction = 1;
  } else {
    direction = -1;
  }
  list.sort((a: any, b: any) => {
    let comparison = 0;
    if (a[property] > b[property]) {
      comparison = 1 * direction;
    } else if (a[property] < b[property]) {
      comparison = -1 * direction;
    }
    return comparison;
  });
  return list;
};

export const datetimeTostring = (dateObj: Date, inputgmt: any) => {
  let gmt = inputgmt ? inputgmt : 7;
  dateObj.setUTCHours(dateObj.getUTCHours() + gmt)
  let tz = gmt >= 0
    ? "+"+gmt.toString().padStart(2, "0") + ":00"
    : "-"+Math.abs(gmt).toString().padStart(2, "0") + ":00";
  return dateObj.getUTCDate() + "-" + dateObj.toLocaleString('default', { month: 'short' }) + "-" + dateObj.getUTCFullYear()
    + " " + dateObj.getUTCHours().toString().padStart(2, '0')
    + ":" + dateObj.getUTCMinutes().toString().padStart(2, '0')
    + " " + tz;
}

export const datetimeTostringDetail = (dateObj: Date, inputgmt: any) => {
  let gmt = inputgmt ? inputgmt : (dateObj.getTimezoneOffset() / -60);
  inputgmt
    ? dateObj.setUTCHours(dateObj.getUTCHours() + gmt)
    : dateObj.setUTCHours(dateObj.getUTCHours())
  let tz = gmt >= 0
    ? "+"+gmt.toString().padStart(2, "0") + ":00"
    : "-"+Math.abs(gmt).toString().padStart(2, "0") + ":00";
  return dateObj.getUTCDate() + "-" + dateObj.toLocaleString('default', { month: 'short' }) + "-" + dateObj.getUTCFullYear()
    + " " + dateObj.getUTCHours().toString().padStart(2, '0')
    + ":" + dateObj.getUTCMinutes().toString().padStart(2, '0')
    + " " + tz;
}

export const messageLoading = () => {
  const param = {
    type: 'info',
    title: `Please Wait...`,
    // confirmBtnBsStyle: 'success',
    // confirmBtnText: 'Ok',
    message: "",
    showConfirm: false,
    showCancel: false,
    focusConfirmBtn: false,
    closeOnClickOutside: false,
  };

  return {
    param,
  }
}

export const messageExpired = () => {
  const param = {
    type: 'danger',
    title: 'Attention!',
    confirmBtnBsStyle: 'danger',
    message: 'Session Expired, Please re-login!',
    confirmBtnText: 'Ok',
    showCancel: false,
    focusConfirmBtn: false,
    closeOnClickOutside: false,
  };

  return {
    param,
  }
}

export const messageFailed = (message: string) => {
  const param = {
    type: 'danger',
    title: 'Attention!',
    confirmBtnBsStyle: 'danger',
    message: message,
    confirmBtnText: 'Ok',
    showCancel: false,
    focusConfirmBtn: false,
    closeOnClickOutside: false,
  };
  return {
    param,
  }
}

export const messageLoadingParam = (message: string) => {
  const param = {
    type: 'info',
    title: `Please Wait...`,
    // confirmBtnBsStyle: 'success',
    // confirmBtnText: 'Ok',
    message: message,
    showConfirm: false,
    showCancel: false,
    focusConfirmBtn: false,
    closeOnClickOutside: false,
  };

  return {
    param,
  }
}



export const messageAction = (res: any, action: string) => {
  let isSucces = true;
  let errorMessage = "";
  const param = {
    type: 'success',
    title: `Data ${action}!`,
    confirmBtnBsStyle: 'success',
    confirmBtnText: 'Ok',
    message: "",
    showCancel: false,
    showConfirm: true,
    focusConfirmBtn: false,
    closeOnClickOutside: true,
    timeout: 0,
  };

  if (res.data) {
    // const message = res.status === 413 ? 'File too large. Total maximum size is 10 MB' : res.data.message;
    if (res.status !== 200) {

      isSucces = false;
      errorMessage = res.data.message;
      if (res.status === 401) errorMessage = 'Please re-login!';
      if (res.data.statusCode === 401) errorMessage = 'Please re-login!';
    } else {
      if (res.data.statusCode !== 200) {
        isSucces = false;
        errorMessage = res.data.message;
        if (res.data.statusCode === 401) errorMessage = 'Please re-login!';
      }
      else {
        param.message = res.data.message;
      }
    }
  } else {
    isSucces = false;
    errorMessage = res.message;
  }
  if (!isSucces) {
    param.type = 'danger';
    param.title = 'Attention!';
    param.confirmBtnBsStyle = 'primary';
    param.message = errorMessage;
  }
  return {
    isSucces,
    param,
  }
}

export const hashFile = (file: any) => {
  return new Promise((resolve, reject) => {

    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }
    fileReader.onload = (e: any) => {
      spark.append(e.target.result); // Append array buffer
      currentChunk += 1;
      if (currentChunk < chunks) {
        loadNext();
      } else {
        const result = spark.end();
        // If result s are used as hash values only, if the contents of the file are the same and the names are different
        // You cannot keep two files if you want to.So add the file name.
        const sparkMd5 = new SparkMD5();
        sparkMd5.append(result);
        sparkMd5.append(file.name);
        const hexHash = sparkMd5.end();
        resolve(hexHash);
      }
    };
    fileReader.onerror = () => {
      console.warn('File reading failed!');
    };
    loadNext();
  }).catch(err => {
    console.log(err);
  });
}

export const mapAndSortMenu = (modules: any, mode: string) => {
  if (mode === 'parent') {
    return modules.filter((val: any) => val.ModuleParentID === 0
      && val.ApplicationCode.toString().toLowerCase() === 'wm'
      && val.AllowView === true)
      .sort((a: any, b: any) => a.ModuleSequencNo - b.ModuleSequenceNo)
      .map((item: any) => ({
        id: item.ModuleID,
        code: item.ModuleCode,
        name: item.ModuleDescription,
        url: item.ModulePath,
        icon: item.ModuleIconPath,
        children: [] as object[],
      }));
  }

  return modules.filter((val: any) => val.ModuleParentID > 0 && val.ApplicationCode.toString().toLowerCase() === 'wm')
    .sort((a: any, b: any) => a.ModuleSequencNo - b.ModuleSequencNo)
    .map((item: any) => ({
      code: item.ModuleCode,
      name: item.ModuleDescription,
      url: item.ModulePath,
      icon: item.ModuleIconPath,
      parentID: item.ModuleParentID,
    }));
}
