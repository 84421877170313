const base64NoImage = `iVBORw0KGgoAAAANSUhEUgAAAZAAAAGQCAMAAAC3Ycb+AAAABGdBTUEAAK/INwWK6QAAABl0RVh0
U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAAzUExURf///8zMzNnZ2ebm5vLy8vz8/M/P
z9bW1t/f3+zs7PX19dLS0vn5+eLi4u/v79zc3Onp6QZDiGYAAAwKSURBVHja7J3pYrI6EEAlC4Sd
93/aq7VCMpmg9mstvZ7zrxRRcyC7M6cTAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwFGw1RVDUSAEEIIQQAhCACH/
X5y90lIUAADwJIO70om/rw3M2Jzb+z5sDcwwmssRswzKtTprmo8eQmPG1qvvZk196UGMHy/34r1v
Jy2mP59Um3Gil7X+fdFhqhv9VdCwHamsvFLbVzH1mCnpopebsxKn9fCidz2/r/UIuQmxSfleHpK2
jo80SVl5U0lqcecv6X9bVcgoLtJ3CLkKCaJkplMrjsTl6JuqumNEXrBqcyHaZVqEXMiKr172imqu
NPqdWz96D7Or9c2MlIQ8wlbernDG1ipPO9cxxYdIrfreV4hpnRvrtKV2Lmq83e0qawMSpvMJYX3N
uL5PHzXUzk2hVoS45J1tX73jtE5ZSH29vbs6a8dn2dPqxL28vmYtyq39WT5rpzkXcjNQO/HAOIRE
pTBm/apBChllV/h2oLkdaPIGIUgha63WyVMMQqJCcHkN1YhzusWac61V+6zFOAmFY9Qxk1XSnI1w
1lMGhGztcZe34Ua9c32XN/JyCBKXbCuEKOeM79fRKgrZhn1rg118kcRbIUSte3wqxMlqLjoWEFKd
MiH2MSHdErXX4pFK51vSmm/RxpuKpDcV0uRC2rtChsmKGRTRf0qrnvS5Wd/ZReS3x7sKMbkQty+k
G/t8UFe8RH4ZszcG9Qh5UsjUqwX5XUIcQp4S4sWkR42Q3xWSzC3OS+cQ8qtCtongxnbaOKRWe1lG
FdIbhQ4hzwipxSRUJsSo44l0xmssLUW+F98hJJ+EcoWBYbxAsp2UdntnhPyrkDG7/1shpM0XSKKG
Jx2p9wj5VyGmOJTPJhd7n0uTc1lRA74E27oOIV8UoixHyfZ72xrRZushc77EXt+WjxHyFSFrZbPI
gWFU/P1HrTWEfMVwymYxxzdcofoOIVasdkTbIbrsmbnsgDO9uqa+Hp2H9LoNVdbXelnVfBYwGW1A
p22DqItr6pWxNvRvOCz8pnFIXxxhL3k7v9mzj+06iZcZEfKQkKl480eDClnajc/fS92X9U4V1nfN
ZWWF3TX5vHm6VS545b28suHOeIQ8P/2eFvbs1YkQ16d9LW1ZxdbCx7vNpHzXeojbahtzOXHdqpXc
3+76a4a5LV3msh4fd8jCcIIv0i3BGDO2w9M3Q8iuZC8zvME6SvVXnk5LUbwcM9rFyRveZH1jeBVr
Yx5vXazfcNx3NCFxP9i+3x6fA1VZ2bDCrz3lmeJ5PdFkez1ba0dTveVM1WHw/f3fT8ErKf3oraoZ
+v16pfW+PyA81khem8qdPQXze0xiLrcOPB6/3pS0NnxsRZztgg0AAAAAAAAAAAAAAACA92Vg+8+R
uOxbpBSOw1KzpfdAfP7aioI4SG01s+n9WM8Hv0JACCAEIYCQXx1Yf8b+vv6aYwqXnmsz69kKT74N
5hpgVKQivGQuXIM3aTkM4VHi35RHSY+0WG8uiXxSx6kIHb+f+n4hIkCMjA3jlfxuCPlBIVlqu7RI
u3rvZzkI+XYhY7ObZ1D1sYW+RMi3C6l300cWfKxByBDy7UKu8TEmNwUl0mUcEU6EuhwR8mNCPtNA
uzoLrGjTmFlJMFjHOOSHhKzBlBZ5xOeKNmsGIT8jJGoxZLCSJY9FuiXdqxxCfkRI1KcyhWixRn1h
QMiPCIkiYYhg7YOWAmHLVtgj5EeEKMc+hbR6Sc9CJUK+dxxSFlLIbmhFI4KQbxViyseMHhl8Ep0x
hLxaiAgy6hCCEIQg5IBCgh6ouhUjGIS8SojV00uM9LJ+Scikp3ZuxGGEvErIoK1YbUcbRuovFrI+
C73XBuoLQl4tZKnyZr3NYowj5GVCtvWQdVeDzTN7uXyhEX5GSFT89SWN9xBv4PJ5ozJcUrdQtD8o
RM+yJqfkWVN/nZDirpNW6we/X068lwspGVnUkSJN+88LOQ1KrVUnA5OtEUHIC4R8/sI2aSZk/oMk
Lj99rZ8Wci7w+CmZlXwt09b7ashW8Qr8ZOePTIRTIfuB+8hUaFt0AAAAAAAAAAAAAAAAAAAAAAAA
AAAAwP+PbPd7IdrJDg+9IgtsCghBCEIQghCEIAQhCHkdzl5pEXJQEIIQQMi/0Flz/RF/Y8Y45+Bn
UkKZRbC7HY+OTeNnphxjbJqUcM1t2O0X7yWrYX1NujPbbkdIKTtiQYhv548vZ0IhneLhaPs05Mi4
fu4mTwZyoZeHvU3jZPTL072sQaTRa1xBiOv13IcFIcl16/AHIhB4k0eBud2frZpZopPBe7q+mG/t
USFtHh4oqELSGEF9d0eIvK4IcHNEH1owsZuRNRhfEvQ4iPCIaqylyMgDQlotWFNQhITSvaMLCbtX
PSSzGriqF1+ojusGYcn31X5x3hfS3QtnZorZ+CIjuZBwx/MRB237ofY6JdTbJL64LVzCPy7E7GY1
LJ+QnpQJae+HrTtsD7QKk3NtqGVw0EYk9Ipuu15Ua/V4yfE85+ET7wpxW2fgfAnb5E5Nkh7RuWn7
pJtWKWSLfnq57mIq7XE/Wn9XPPhrc2DkTbZ2T9av+dmkL6LRaLOCuitkFpVJyO71SEi4vs8Wkq4u
CQmi85F/suMxyo84iopAFn/0tXz6DDnZVx4fFeLlneuzh8zkLcDWG2l1Ib4qffQDPyLdYo2JP+Ek
YxwGWVXL29nZ8TKqNJlk87CQyYbLqHK7cZtiIxONiAaZH1EIWfJAdvNfaEUuJdLljXzWAepKd++1
dIZyp+rB6ffOn+63+q3S/a51IabcHzl61zdSk6e9EzVQqw/eIzXzF4VEavqikLiymUT7JoRoL7n3
4Y9Wf81KWFZhoNktzLiL9DUhfhr7vANltDiavmDApY92/JJGTV1yQIbJiq7+KetVTUnFnU0Kda1I
av+8ELeEdJCZCQnqbOKiCVkrXxfR/IkJ4W5Uxtp5VR3ilnJOLxGPC74mxLfKnIHdVyiOpkJstcN0
YB2TPvOhjFV89Mwn38iqgcOfEeLVSY6fE3LckYgsiVqpeZutuzIoraKMGl4/L8TV+iXeUEhST8xL
p+W1abdqyuZfKJlbrEM7PN/tTeYW+3Hy5m2FLNsc3XWRThOyNut+fVgGTem8DF8ah2xK689FPfNY
o272GvU1TbJROOzIcJ0YdKWBYdysT0PepHdZdPanhSximmpHiDntLBGmf05/MRXMlOUeUIXcCj20
+eB3zFKuPS0kn1Euj9STvrr49Hq3t/5LQsasJtCzz38WWT3n37HJHIUnhfh8aFMeqU9K21btPzB/
KZB/3lZaVYhY6RnL1YZWvdwRojyUxW6vutBu9ueyogbczXZx7g8IGbN7MxXi027poAiZsorkaSE+
868sKbp8HnTZn+2t80nLcHQhfd7rSk8MxYxp2a07f1lIm3WkFSHrGvp2kwx31kPW/RbT4Xu9VlRC
SynFUFdckxa1ezTQrB9t1NcXDHKgOedCbht5trFkKNWeQezxmur0jY7cy6rms4Apnl8UNW00Gq/V
fsG5eIeTT/bcPdvLOpe1Pw3xPIxRhJyf52CjSeWtAi2vqVfNaKOpzwMv4fbFsexyKjXr40nte+Z0
Dwppi1eos7p1b9D96K6TI+dEnPIy0Kdzo7ttOBUeEXmJ5dGpk6Z4iU68YspOnfc6fKNq+dD9YDnL
2nSFNZxQSoKabX2cB3En3p/LkrPFdhJP4+0VTr6b8XtCtAmt/uAJ+NKbaPb5RhTRrGfTQGJzsM02
ojywczGpiuop24iyCjn5uTQiUvf2ZosL8+EXC1206Ork8ocyWle+0LJ964/t5WK/xyPrIWOdbmmf
01NMVNZbtWXc6Z6QB7IlHnDFcAnGmPFfkgW6j3yExXSEjyzMTJeUhbN9pMS6y7uF5dHPO7Qfn27+
l48HAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIfhPwEGAIM3WVW6WlTLAAAAAElF
TkSuQmCC`;

export default base64NoImage;
