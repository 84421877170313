import React from 'react';


const routes = [
  {
    path: '/assembly-disassembly',
    exact: true,
    name: 'Assembly/Dissassembly',
    component: React.lazy(() => import('./views/ListAssemblyDissassembly')),
  },
  {
    path: '/assembly-disassembly/assembly/create',
    exact: true,
    name: 'Create Assembly',
    component: React.lazy(() => import('./views/CreateAssembly')),
  },
  {
    path: '/assembly-disassembly/assembly/update/:assemblyHeaderId',
    exact: true,
    name: 'Update Assembly',
    component: React.lazy(() => import('./views/UpdateAssembly')),
  },
  {
    path: '/assembly-disassembly/disassembly/create',
    exact: true,
    name: 'Create Disassembly',
    component: React.lazy(() => import('./views/CreateDisassembly'))
  },
  {
    path:'/assembly-disassembly/disassembly/create/allocation/:productId',
    exact: true,
    name: 'Location to Allocate',
    component: React.lazy(() => import('./views/DisassemblyDetail')),
  },
  {
    path:'/assembly-disassembly/disassembly/update/allocation/:productId',
    exact: true,
    name: 'Location to Allocate',
    component: React.lazy(() => import('./views/DisassemblyDetail')),
  },
  {
    path:'/assembly-disassembly/disassembly/update/:disassemblyHeaderId',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdateDisassembly'))
  }
];

export default routes;
