// account
export const MASTERTYPEENDPOINT = 'master-types-all';
export const MASTERTYPEFETCH = 'MASTERTYPEFETCH';
export const MASTERTYPESUCCESS = 'MASTERTYPESUCCESS';
export const MASTERTYPEFAILED = 'MASTERTYPEFAILED';

export const MASTERWAREHOUSEENDPOINT = 'inventory/master/warehouse/list';
export const MASTERWAREHOUSEFETCH = 'MASTERWAREHOUSEFETCH';
export const MASTERWAREHOUSESUCCESS = 'MASTERWAREHOUSESUCCESS';
export const MASTERWAREHOUSEFAILED = 'MASTERWAREHOUSEFAILED';

export const MASTEROWNERENDPOINT = 'inbound/owners';
export const MASTEROWNERFETCH = 'MASTEROWNERFETCH';
export const MASTEROWNERSUCCESS = 'MASTEROWNERSUCCESS';
export const MASTEROWNERFAILED = 'MASTEROWNERFAILED';

export const MASTERGOODSSTATUSINVENTORYENDPOINT = 'inventory/goodsstatus/all';
export const MASTERGOODSSTATUSINVENTORYFETCH = 'MASTERGOODSSTATUSINVENTORYFETCH';
export const MASTERGOODSSTATUSINVENTORYSUCCESS = 'MASTERGOODSSTATUSINVENTORYSUCCESS';
export const MASTERGOODSSTATUSINVENTORYFAILED = 'MASTERGOODSSTATUSINVENTORYFAILED';

export const MASTERZONEENDPOINT = 'inventory/zone/getzone';
export const MASTERZONEFETCH = 'MASTERZONEFETCH';
export const MASTERZONESUCCESS = 'MASTERZONESUCCESS';
export const MASTERZONEFAILED = 'MASTERZONEFAILED';

export const MASTERSTORAGEENDPOINT = 'inventory/master/storage';
export const MASTERSTORAGEFETCH = 'MASTERSTORAGEFETCH';
export const MASTERSTORAGESUCCESS = 'MASTERSTORAGESUCCESS';
export const MASTERSTORAGEFAILED = 'MASTERSTORAGEFAILED';

export const MASTERUOMINVENTORYENDPOINT = 'inventory/master/uoms';
export const MASTERUOMINVENTORYFETCH = 'MASTERUOMINVENTORYFETCH';
export const MASTERUOMINVENTORYSUCCESS = 'MASTERUOMINVENTORYSUCCESS';
export const MASTERUOMINVENTORYFAILED = 'MASTERUOMINVENTORYFAILED';

export const MASTERCUSTOMSDOCUMENTINVENTORYENDPOINT = 'inventory/master/customsdocuments';
export const MASTERCUSTOMSDOCUMENTINVENTORYFETCH = 'MASTERCUSTOMSDOCUMENTINVENTORYFETCH';
export const MASTERCUSTOMSDOCUMENTINVENTORYSUCCESS = 'MASTERCUSTOMSDOCUMENTINVENTORYSUCCESS';
export const MASTERCUSTOMSDOCUMENTINVENTORYFAILED = 'MASTERCUSTOMSDOCUMENTINVENTORYFAILED';

export const MASTERLOCATIONPROJECTENDPOINT = 'inventory/locationproject/getlocationproject';
export const MASTERLOCATIONPROJECTFETCH = 'MASTERLOCATIONPROJECTFETCH';
export const MASTERLOCATIONPROJECTSUCCESS = 'MASTERLOCATIONPROJECTSUCCESS';
export const MASTERLOCATIONPROJECTFAILED = 'MASTERLOCATIONPROJECTFAILED';

export const MASTEROWNERINVENTORYENDPOINT = 'inventory/owner/getOwners';
export const MASTEROWNERINVENTORYFETCH = 'MASTEROWNERFETCH';
export const MASTEROWNERINVENTORYSUCCESS = 'MASTEROWNERSUCCESS';
export const MASTEROWNERINVENTORYFAILED = 'MASTEROWNERFAILED';

export const MASTERCUSTOMSDOCUMENTENDPOINT = 'inbound/customsdocuments';
export const MASTERCUSTOMSDOCUMENTFETCH = 'MASTERCUSTOMSDOCUMENTFETCH';
export const MASTERCUSTOMSDOCUMENTSUCCESS = 'MASTERCUSTOMSDOCUMENTSUCCESS';
export const MASTERCUSTOMSDOCUMENTFAILED = 'MASTERCUSTOMSDOCUMENTFAILED';

export const MASTERSUPPLIERENDPOINT = 'inbound/suppliers';
export const MASTERSUPPLIERFETCH = 'MASTERSUPPLIERFETCH';
export const MASTERSUPPLIERSUCCESS = 'MASTERSUPPLIERSUCCESS';
export const MASTERSUPPLIERFAILED = 'MASTERSUPPLIERFAILED';

export const MASTERSUPPLIERINVENTORYENDPOINT = 'inventory/master/supplier/list';
export const MASTERSUPPLIERINVENTORYFETCH = 'MASTERSUPPLIERINVENTORYFETCH';
export const MASTERSUPPLIERINVENTORYSUCCESS = 'MASTERSUPPLIERINVENTORYSUCCESS';
export const MASTERSUPPLIERINVENTORYFAILED = 'MASTERSUPPLIERINVENTORYFAILED';

export const MASTERCURRENCYENDPOINT = 'inbound/currencies';
export const MASTERCURRENCYFETCH = 'MASTERCURRENCYFETCH';
export const MASTERCURRENCYSUCCESS = 'MASTERCURRENCYSUCCESS';
export const MASTERCURRENCYFAILED = 'MASTERCURRENCYFAILED';

export const MASTERSKUPRODUCTENDPOINT = 'inventory/skuproducts';
export const MASTERSKUPRODUCTFETCH = 'MASTERSKUPRODUCTFETCH';
export const MASTERSKUPRODUCTSUCCESS = 'MASTERSKUPRODUCTSUCCESS';
export const MASTERSKUPRODUCTFAILED = 'MASTERSKUPRODUCTFAILED';

export const MASTERSKUPRODUCTGROUPENDPOINT = 'inbound/skuproductgroups';
export const MASTERSKUPRODUCTGROUPFETCH = 'MASTERSKUPRODUCTGROUPFETCH';
export const MASTERSKUPRODUCTGROUPSUCCESS = 'MASTERSKUPRODUCTGROUPSUCCESS';
export const MASTERSKUPRODUCTGROUPFAILED = 'MASTERSKUPRODUCTGROUPFAILED';

export const MASTERSKUPRODUCTGROUPINVENTORYENDPOINT = 'inventory/skuproductgroup/getskuproductgroups';
export const MASTERSKUPRODUCTGROUPINVENTORYFETCH = 'MASTERSKUPRODUCTGROUPINVENTORYFETCH';
export const MASTERSKUPRODUCTGROUPINVENTORYSUCCESS = 'MASTERSKUPRODUCTGROUPINVENTORYSUCCESS';
export const MASTERSKUPRODUCTGROUPINVENTORYFAILED = 'MASTERSKUPRODUCTGROUPINVENTORYFAILED';

export const MASTERUOMENDPOINT = 'inbound/uoms';
export const MASTERUOMFETCH = 'MASTERUOMFETCH';
export const MASTERUOMSUCCESS = 'MASTERUOMSUCCESS';
export const MASTERUOMFAILED = 'MASTERUOMFAILED';

export const MASTERLOCATIONENDPOINT = 'inventory/master/locationbin/list';
export const MASTERLOCATIONFETCH = 'MASTERLOCATIONFETCH';
export const MASTERLOCATIONSUCCESS = 'MASTERLOCATIONSUCCESS';
export const MASTERLOCATIONFAILED = 'MASTERLOCATIONFAILED';

export const MASTERGOODSSTATUSENDPOINT = 'inbound/goodsstatuses';
export const MASTERGOODSSTATUSFETCH = 'MASTERGOODSSTATUSFETCH';
export const MASTERGOODSSTATUSSUCCESS = 'MASTERGOODSSTATUSSUCCESS';
export const MASTERGOODSSTATUSFAILED = 'MASTERGOODSSTATUSFAILED';

export const MASTERRECEIVINGSTATUSENDPOINT = 'inbound/receivingstatuses';
export const MASTERRECEIVINGSTATUSFETCH = 'MASTERRECEIVINGSTATUSFETCH';
export const MASTERRECEIVINGSTATUSSUCCESS = 'MASTERRECEIVINGSTATUSSUCCESS';
export const MASTERRECEIVINGSTATUSFAILED = 'MASTERRECEIVINGSTATUSFAILED';

export const MASTERCONTAINERTYPEENDPOINT = 'inbound/containertypes';
export const MASTERCONTAINERTYPEFETCH = 'MASTERCONTAINERTYPEFETCH';
export const MASTERCONTAINERTYPESUCCESS = 'MASTERCONTAINERTYPESUCCESS';
export const MASTERCONTAINERTYPEFAILED = 'MASTERCONTAINERTYPEFAILED';

export const MASTERCUSTOMERENDPOINT = "inventory/customer/all";
export const MASTERCUSTOMERFETCH = 'MASTERCUSTOMERFETCH';
export const MASTERCUSTOMERSUCCESS = 'MASTERCUSTOMERSUCCESS';
export const MASTERCUSTOMERFAILED = 'MASTERCUSTOMERFAILED';

export const MASTERCUSTOMERBILLTOENDPOINT = "inventory/customer/withfiltercompany";
export const MASTERCUSTOMERBILLTOFETCH = "MASTERCUSTOMERBILLTOFETCH";
export const MASTERCUSTOMERBILLTOSUCCESS = "MASTERCUSTOMERBILLTOSUCCESS";
export const MASTERCUSTOMERBILLTOFAILED = "MASTERCUSTOMERBILLTOFAILED";

export const MASTERCUSTOMERINVENTORYENDPOINT = 'inventory/master/customer';
export const MASTERCUSTOMERINVENTORYFETCH = 'MASTERCUSTOMERINVENTORYFETCH';
export const MASTERCUSTOMERINVENTORYSUCCESS = 'MASTERCUSTOMERINVENTORYSUCCESS';
export const MASTERCUSTOMERINVENTORYFAILED = 'MASTERCUSTOMERINVENTORYFAILED';

export const MASTERPACKAGETYPEENDPOINT = 'inbound/packagetypes';
export const MASTERPACKAGETYPEFETCH = 'MASTERPACKAGETYPEFETCH';
export const MASTERPACKAGETYPESUCCESS = 'MASTERPACKAGETYPESUCCESS';
export const MASTERPACKAGETYPEFAILED = 'MASTERPACKAGETYPEFAILED';