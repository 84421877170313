/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/putaway',
    exact: true,
    name: 'Putaway',
    component: React.lazy(() => import('./views/ListPutAway')),
  },
  // {
  //   path: '/receiving/create',
  //   exact: true,
  //   name: 'Create',
  //   component: React.lazy(() => import('./views/CreateReceiving')),
  // },
  {
    path: '/putaway/detail/:putAwayAsnNumber',
    exact: true,
    name: 'Detail',
    component: React.lazy(() => import('./views/DetailPutAway')),
  },
  {
    path: '/putaway/detail/:putAwayAsnNumber/create/suggestion/:receivingLineId',
    exact: true,
    name: 'Create Putaway Suggestion',
    component: React.lazy(() => import('./views/CreatePutAwaySuggestion')),
  },
  {
    path: '/putaway/detail/:putAwayAsnNumber/update/suggestion/:putAwayId',
    exact: true,
    name: 'Update Putaway Suggestion',
    component: React.lazy(() => import('./views/UpdatePutAwaySuggestion')),
  },
  {
    path: '/putaway/detail/:putAwayAsnNumber/create/actual/:putAwayId',
    exact: true,
    name: 'Actual Putaway',
    component: React.lazy(() => import('./views/CreateActualPutAway')),
  },
  {
    path: '/putaway/detail/:putAwayAsnNumber/update/actual/:putAwayId',
    exact: true,
    name: 'Update Actual Putaway',
    component: React.lazy(() => import('./views/UpdatePutAwayActual')),
  },
  
];

export default routes;