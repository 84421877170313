// this is an auto generated file from ENV Variables
export const APIHOST: string = 'apiintpprod.azure-api.net/v1.0.0';
export const APIPORT: string = '80';
export const APIPROTOCOL: string = 'https';
export const MENU: any = '[{"title":true,"name":"Warehouse Management","wrapper":{"element":"","attributes":{}},"class":""},{"name":"Dashboard","url":"/dashboard","icon":"icon-speedometer"},{"name":"Inbound","url":"/Inbound","icon":"fa fa-tasks","children":[{"name":"ASN","url":"/asn","icon":"fa fa-magic"},{"name":"Receiving","url":"/receiving","icon":"fa fa-magic"},{"name":"Putaway","url":"/putaway","icon":"fa fa-magic"}]},{"name":"Outbound","url":"/Outbound","icon":"fa fa-tasks","children":[{"name":"Reservation","url":"/reservation","icon":"fa fa-magic"},{"name":"Picking","url":"/picking","icon":"fa fa-magic"},{"name":"Packing","url":"/packing","icon":"fa fa-magic"},{"name":"Goods Issue","url":"/goodsissue","icon":"fa fa-magic"}]},{"name":"Inventory","url":"/Inventory","icon":"fa fa-tasks","children":[{"name":"Inventory Analyzer","url":"/inventoryanalyzer","icon":"fa fa-magic"},{"name":"Inventory Transfer","url":"/inventorytransfer","icon":"fa fa-magic"},{"name":"Inventory Adjustment","url":"/inventoryadjustment","icon":"fa fa-magic"},{"name":"Cycle Count","url":"/cyclecount","icon":"fa fa-magic"}]},{"name":"Report","url":"/report","icon":"fa fa-tasks","children":[{"name":"Goods Receipt Report","url":"/report/inbound","icon":"fa fa-magic"},{"name":"Report Inventory Transfer","url":"/report/inventorytransfer","icon":"fa fa-magic"},{"name":"Goods Issue Report","url":"/report/outbound","icon":"fa fa-magic"},{"name":"Report Stock Onhand","url":"/report/stockonhand","icon":"fa fa-magic"}]},{"name":"Master","url":"/master","icon":"fa fa-tasks","children":[{"name":"Master SKU/Product","url":"/master/skuproduct","icon":"fa fa-magic"}]}]';
export const LANDINGHOST: string = 'lintas.interport.co.id';
export const NOLANDINGHOST: boolean = false;
export const CLIENT_ID: string = 'd89e054a-49bc-4984-b2e8-a7e9f9dbd76c';
export const CLIENT_ID_B2C: string = '66ecd782-5ab0-4b64-bf3a-67857c4915ad';
export const WORKSPACE_ID: string = 'cef958c2-119b-4784-8c50-00ff222d2ac0';
export const BASE_URL_POWER_BI: string = 'https://api.interport.co.id/pbi/getEmbedToken';
export const REPORT_ID: string = 'b9923a48-fc33-4266-b74f-4a2939dcd445';
export const REDIRECT_URI: string = 'https://wm-lintas.interport.co.id';
export const SIGN_IN_URL: string = 'https://login.microsoftonline.com/ac6d4546-7d1e-48c7-b04e-0cc38318aec8';
export const SIGN_IN_URL_B2C: string = 'https://interportb2c.b2clogin.com/tfp/interportb2c.onmicrosoft.com/B2C_1A_signup_signin';
export const APP_INSIGHT_KEY: string = '47467f87-ef38-4af3-8743-c985e0cd1503';
