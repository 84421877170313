import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action, State } from '../../../../config/interfaces';
import { HttpService } from '../../../../utilities';
import { masterCustomerbilltoFailed, masterCustomerbilltoSuccess } from './ActionMasterCustomerBillto';
import { MASTERCUSTOMERBILLTOENDPOINT, MASTERCUSTOMERBILLTOFETCH } from '../../config/Constants';

function* workerSagaMasterCustomerBillto(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };
    let UserTypeID = params.data.userinfo.UserTypeID ? params.data.userinfo.UserTypeID : 0;
    let Company = "";

    if (UserTypeID !== 1 && UserTypeID !== 5) {
      Company = params.data.userinfo.Company ? params.data.userinfo.Company : "";
    }

    const response = yield call(HttpService.get, MASTERCUSTOMERBILLTOENDPOINT, { customerName: Company }, headers);

    if (!response.data) throw (response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.customerCode} - ${item.customerName}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterCustomerbilltoSuccess(result));
    } else {
      yield put(masterCustomerbilltoFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterCustomerbilltoFailed(error.message));
  }
}

export const watcherSagaMasterCustomerbillto = [
  takeLatest(MASTERCUSTOMERBILLTOFETCH, workerSagaMasterCustomerBillto),
];
