export const RESERVATIONVALUE = "RESERVATIONVALUE";
export const RESERVATIONENDPOINT = "outbound/reservations";

export const initialPage = {
  pagenumber: 0,
  direction: "DESC",
  order: "createdDate",
  pageSize: 10,
};

export const ActionAlert = {
  Close: "Close",
  Correction: "Correction",
  Duplicate: "Duplicate",
  Save: "Save",
  Cancel: "Cancel",
  Send: "Send",
  Remove: "Remove",
  PostGL: "PostGL",
  Confirm: "Confirm",
  Allocated: "Allocated",
  Update: "Update",
  Rollback: "Rollback",
};

export const FilterProduct = [
  "warehouseId",
  "ownerId",
  "crossDocking",
  "clientId",
  "previousAjuNumber",
  "previousNopen",
];

export const FilterAjuNumber = [
  "warehouseId",
  "ownerId",
  "crossDocking",
  "clientId",
];

export const ReservationStatusAllowAllocated = [
  "Confirmed",
  "Allocated",
  "Partial Received",
];

export const ReservationStatusAllowEditShipTo = [
  "Allocated",
  "Picked",
  "Partial Picked",
];

export const defaultReservationPlannings = ["Select"];

export const ReservationType = {
  Exist: "Existing Asn",
  Blind: "Blind Reservation",
};

export const shipToTypes = {
  Internal: "Internal",
  External: "External",
};

export const StatusAsn = {
  Closed: "Closed",
  Open: "Open",
  Proceed: "Proceed",
  Cancelled: "Cancelled",
  Received: "Received",
  PartialReceived: "Partial Received",
};

export const StatusReservation = {
  Open: "Open",
  Confirm: "Confirmed",
  Cancelled: "Cancelled",
  Allocated: "Allocated",
  PartialAllocated: "PartialAllocated",
  Shipped: "Shipped",
  PartialShipped: "Partial Shipped",
};

export const deliveryTypes = [
  {
    id: "Ocean",
    name: "Ocean",
  },
  {
    id: "Air",
    name: "Air",
  },
  {
    id: "Land",
    name: "Land",
  },
  {
    id: "Rail",
    name: "Rail",
  },
];

export const defaultRequest = {
  totalItems: 0,
  currentPage: 0,
  pageSize: 0,
  totalPages: 0,
  startPage: 0,
  endPage: 0,
  startIndex: 0,
  endIndex: 0,
  pages: [],
  direction: "",
  orderBy: "",
};

export const defaultReservationList = {
  content: [],
  first: 1,
  last: 0,
  page: 0,
  totalPages: 1,
  totalElements: 0,
  pageSize: 10,
  startPage: 0,
  endPage: 0,
  pages: [],
  direction: "ASC",
  orderBy: "",
};

export const defaultFilter = {
  reservationDateFrom: "",
  reservationDateTo: "",
  ownerId: "",
  warehouseId: "",
  reservationNumber: "",
  reservationDate: "",
  clientId: "",
  status: "",
  poNumber: "",
  blAwbNumber: "",
  ajuNumber: "",
  invoice: "",
};

export const defaultMasterType = {
  id: "",
  code: "",
  name: "",
  description: "",
  createdBy: "",
  createdDate: "",
  modifiedBy: "",
  lastUpdatedDate: "",
  activeFlag: "",
};

export const defaultCombobox = {
  crossDockings: [
    { id: "", code: "Select" },
    { id: "Yes", code: "Yes" },
    { id: "No", code: "No" },
  ],
  ownerWarehouses: [],
  ownerShipto: [],
  warehouses: [],
  warehousesByOwner: [],
  shipTowarehousesByOwner: [],
  customsDocuments: [],
  customers: [],
  customersShipTo: [],
  customersbillto: [],
  suppliers: [],
  deliveryTypes: [],
  packageTypes: [],
  skuProducts: [],
  skuProductsByOwner: [],
  skuProductGroups: [],
  uoms: [],
  status: [],
  locations: [],
  locationsByOwner: [],
  previousAjuNumbers: [],
  previousNopens: [],
  reservationsources: [],

  plannings: [],
};

export const defaultHeaderReservation: any = {
  reservationHeaderId: "",
  ownerId: "",
  ownerCode: "",
  plbType: false,
  isGenerateReceiving: false,
  warehouseId: "",
  reservationNumber: "",
  poNumber: "",
  poDate: "",
  othersReference: "",
  deliveryType: "",
  crossDocking: "",
  reservationDate: "",
  blAwbNumber: "",
  requestDeliveryDate: "",
  remark: "",
  customsDocumentId: "",
  ajuNumber: "",
  ajuDate: "",
  nopen: "",
  invoice: "",
  status: "",
  previousAjuNumber: [],
  previousNopen: "",
  shipToType: "",
  shipToWarehouseId: "",
  shipToOwnerId: "",
  clientId: "",
  clientName: "",
  clientAddress: "",
  clientCity: "",
  clientCountry: "",
  shipToClientId: "",
  shipToClientName: "",
  shipToClientAddress: "",
  shipToClientCity: "",
  shipToClientCountry: "",
  supplierId: "",
  supplierName: "",
  supplierAddress: "",
  supplierCity: "",
  supplierCountry: "",
  attribute1: "",
  attribute2: "",
  attribute3: "",
  attribute4: "",
  attribute5: "",
  attribute6: "",
  reservationlines: [],
  reservationdocument: [],
  reservationallocation: [],
  reservationmapping: [],

  isPlanning: false,
  planningHeaderId: "",
};

export const dataInventoryFormat: any = {
  inventoryId: "",
  receiveId: "",
  ownerId: "",
  warehouseId: "",
  crossDocking: "",
  skuProductId: "",
  clientId: "",
  locationId: "",
  receivedQuantity: 0,
  availableQuantity: 0,
  onHandQuantity: 0,
  pickedQuantity: 0,
  shippedQuantity: 0,
  poNumber: "",
  blAwbNumber: "",
  ajuNumber: "",
  nopen: "",
  previousAjuNumber: "",
  previousNopen: "",
  serialNumber: "",
  serialNumber2: "",
};

export const counterFormat: any = {
  allocated: "",
  notAllocated: "",
};

export const saveFormat: any = {
  asnHeaderId: "",
  header: {},
  lines: [],
  documentTitle: [],
  totalEvidence: 0,
  uploadDocument: [],
};

export const saveFormatDetail: any = {
  lines: {},
  linesDetail: [],
};

export const defaultDocument = {
  documents: [
    {
      id: "",
      modules: "",
      title: "",
      fileName: "",
      originalName: "",
      createdBy: "",
      createdDate: "",
      file: {},
    },
  ],
  newDocuments: [],
  deleteDocuments: [],
};

export const defaultFile = {
  id: "",
  modules: "",
  title: "",
  fileName: "",
  originalName: "",
  createdBy: "",
  createdDate: "",
  file: {},
};

export const defaultTitle = {
  id: 0,
  title: "",
};

export const dataLineFormat: any = {
  receivingLinesId: "",
  skuProductId: "",
  skuProductCode: "",
  generatedProductCode: "",
  skuProductDescription: "",
  skuProductUrl: "",
  skuProductWeight: 0,
  skuProductVolume: 0,
  quantity: 0,
  availableQuantity: 0,
  allocatedQuantity: 0,
  uomId: "",
  uomCode: "",
  packageTypeId: "",
  handlingUnitTypeNumber: "",
  isAllocated: false,
  totalWeight: 0,
  totalVolume: 0,
  attribute1: "",
  attribute2: "",
  attribute3: "",
  attribute4: "",
  attribute5: "",
  attribute6: "",
  packing: true,
  remainQuantity: "",
};

export const dataLineAllocation: any = {
  inventoryId: "",
  receiveId: "",
  skuProductId: "",
  warehouseId: "",
  warehouseName: "",
  locationId: "",
  locationName: "",
  conditionId: "",
  conditionName: "",
  serialNumber: "",
  serialNumber2: "",
  packageNumber: "",
  previousAjuNumber: "",
  previousNopen: "",
  receiptDate: "",
  availableQuantity: "",
  allocatedQuantity: "",
  isQuarantine: "",
};

export const dataLineDetailFormat: any = {
  receivingLinesId: "",
  packageNumber: "",
  serialNumber: "",
  serialNumber2: "",
  receiveStatusId: "",
  receivingQuantity: 0,
  goodsStatusId: "",
  receivedLocationId: "",
  expiredDate: "",
};

export const defaultAsnFilterList: any = {
  asnNumbers: [],
  blAwbNumbers: [],
  poNumbers: [],
  invoices: [],
  ajuNumbers: [],
};

export const defaultOptionCreate: any = {
  selectedOption: "",
  asnNumber: "",
  poNumber: "",
  blAwbNumber: "",
  ajuNumber: "",
};

export const StatusFilter: any = [
  {
    id: "",
    name: "Select",
  },
  {
    id: "Open",
    name: "Open",
  },
  {
    id: "Confirmed",
    name: "Confirmed",
  },
  {
    id: "Allocated",
    name: "Allocated",
  },
  {
    id: "Picked",
    name: "Picked",
  },
  {
    id: "Partial Picked",
    name: "Partial Picked",
  },
  {
    id: "Packed",
    name: "Packed",
  },
  {
    id: "Partial Packed",
    name: "Partial Packed",
  },
  {
    id: "Shipped",
    name: "Shipped",
  },
  {
    id: "Partial Shipped",
    name: "Partial Shipped",
  },
  {
    id: "Cancelled",
    name: "Cancelled",
  },
];

export const reservationSources: any = [
  {
    id: "",
    name: "",
  },
  {
    id: "Planning",
    name: "Planning",
  },
];

export const defaultReservationFilterList: any = {
  reservationNumbers: [],
  blAwbNumbers: [],
  poNumbers: [],
  invoices: [],
  ajuNumbers: [],
};

export const showField: any = {
  othersRef: false,
  blAwbNumber: false,
  ajuNumber: false,
  nopen: false,
  crossDocking: false,
  invoice: false,
};

export const tableHeader: any = [
  {
    id: "othersRef",
    name: "Others Reference",
  },
  {
    id: "blAwbNumber",
    name: "BL/AWB Number",
  },
  {
    id: "ajuNumber",
    name: "AJU Number",
  },
  {
    id: "nopen",
    name: "Nopen",
  },
  {
    id: "crossDocking",
    name: "Cross Docking",
  },
  {
    id: "invoice",
    name: "Invoice",
  },
];

export const parameterPickingList: any = {
  reservationHeaderId: "",
  ownerId: "",
  ownerCode: "",
  warehouseId: "",
  reservationNumber: "",
  poNumber: "",
  othersReference: "",
  reservationDate: "",
  blAwbNumber: "",
  customsDocumentId: "",
  ajuNumber: "",
  nopen: "",
  previousAjuNumber: "",
  clientId: "",
  clientName: "",
  clientAddress: "",
  clientCity: "",
  clientCountry: "",
};

export const PickingType: any = {
  Manual: "Manual",
  Automate: "Automate",
};
