
export const defaultCombobox = {
  ownerWarehouses: [],
  warehouses: [],
  warehousesByOwner: [],
  crossDockings: [
    { id: "", code: "Select" },
    { id: "Yes", code: "Yes" },
    { id: "No", code: "No" }],
  customsDocuments: [],
  customers: [],
  customersbillto: [],
  suppliers: [],
  currencies: [],
  skuProducts: [],
  skuProductsByOwner: [],
  skuProductGroups: [],
  uoms: [],
  deliveryTypes: [],
  status: [],
  locations: [],
  locationsAll: [],
  locationsByWarehouse: [],
  goodsStatus: [],
  receivingStatus: [],
  containerTypes: [],
};

export const defaultInputDisassembly = {
  ownerId: '',
  clientId: '',
  warehouseId: '',
  locationId: '',
  disassemblyNumber: '',
  disassemblyDate: '',
  productId: '',
  uom: '',
  quantity: '',
  ajuNumber: '',
  status: '',
  disassemblyLines: [],
  disassemblyAllocation: [],
  createdBy: '',
  createdDate: '',
  modifiedBy: '',
  modifiedDate: '',
};

export const dataLineFormat: any = {
  disassemblyLinesId: '',
  locationId: '',
  availableQuantity: '',
  receiptDate: '',
  serialNumber: '',
  packageNumber: '',
  poNumber: '',
  ajuNumber: '',
  expiredDate: '',
  goodsStatusId: '',
  supplierId: '',
}

export const defaultFilter = {
  locationId: '',
  ajuNumber: '',
  supplierId: '',
  goodsStatusId: '',
}

export const ActionAlert = {
  Close: "Close",
  Correction: "Correction",
  Duplicate: "Duplicate",
  Save: "Save",
  Remove: "Remove",
  PostGL: "PostGL",
  Update: "Update",
}

export const defaultAllocation = {
  skuProductId: '',
  locationId: '',
  nopen: '',
  supplier: '',
  expiredDate: '',
  quantity: '',
}

export const DISASSEMBLYCHANGE = 'DISASSEMBLYCHANGE';
export const DISASSEMBLYBYOWNER = 'DISASSEMBLYBYOWNER';
export const DISASSEMBLYLINES = 'DISASSEMBLYLINES';
export const DISASSEMBLYDETAIL = 'DISASSEMBLYDETAIL';
export const DISASSEMBLYALLOCATE = 'DISASSEMBLYALLOCATE';
export const BOMHEADER = 'BOMHEADER';