import { Action, State } from '../../../config/interfaces';

import {
  initialAction,
  initialState,
} from '../../../config'

import {
  RECEIVINGVALUE,
  PACKAGEDIMENSIONVALUE
} from '../config/constant';

export function ReducerReceiving(state: State = initialState, action: Action = initialAction) {
  switch (action.type) {
    case RECEIVINGVALUE:
      return {
        ...state,
        fetch: true,
        data: action.data,
      };
      
    default:
      return state;
  }
}

export function ReducerPackageDimension(state: State = initialState, action: Action = initialAction) {
  switch (action.type) {
    case PACKAGEDIMENSIONVALUE:
      return {
        ...state,
        packageDimension: action.data,
      };
      
    default:
      return state;
  }
}