import {
  Action,
  State,
} from '../../../../config/interfaces';

import {
  initialState,
  initialAction,
} from '../../../../config';

import {
  MASTERCONTAINERTYPEFETCH,
  MASTERCONTAINERTYPESUCCESS,
  MASTERCONTAINERTYPEFAILED,
} from '../../config';

export function ReducerMasterContainerType(state: State = initialState, action: Action = initialAction) {
  switch (action.type) {
    case MASTERCONTAINERTYPEFETCH:
      return {
        ...state,
        fetch: true,
        data: action.data,
        action: action.type,
      };

    case MASTERCONTAINERTYPESUCCESS:
      return {
        ...state,
        fetch: false,
        res: action.data,
        action: action.type,
      };

    case MASTERCONTAINERTYPEFAILED:
      return {
        ...state,
        fetch: false,
        err: action.data,
        action: action.type,
      };

    default:
      return state; 
  }
}