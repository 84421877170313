import { initialState } from '../../../../config';
import { Action, State } from '../../../../config/interfaces';
import { SAVE_SEARCH_VALUE } from '../constant';

export function ReducerHeaderSearch(state: State = initialState, action: Action) {
  switch (action.type) {
    case SAVE_SEARCH_VALUE:
      return {
        ...state,
        fetch: true,
        data: action.data,
      };
      
    default:
      return state;
  }
}