/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/asn',
    exact: true,
    name: 'ASN',
    component: React.lazy(() => import('./views/ListAsn')),
  },
  { 
    path: '/asn/create',
    exact: true,
    name: 'Create',
    component: React.lazy(() => import('./views/CreateAsn')),
  },
  {
    path: '/asn/update/:headerAsnId',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdateAsn')),
  },
];

export default routes;