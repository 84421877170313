import { Action, State } from "../../../../config/interfaces";

import { initialState, initialAction } from "../../../../config";

import {
  MASTERCUSTOMERBILLTOFAILED,
  MASTERCUSTOMERBILLTOFETCH,
  MASTERCUSTOMERBILLTOSUCCESS,
} from "../../config/Constants";

export function ReducerMasterCustomerBillto(
  state: State = initialState,
  action: Action = initialAction
) {
  switch (action.type) {
    case MASTERCUSTOMERBILLTOFETCH:
      return {
        ...state,
        fetch: true,
        data: action.data,
        action: action.type,
      };

    case MASTERCUSTOMERBILLTOSUCCESS:
      return {
        ...state,
        fetch: false,
        res: action.data,
        action: action.type,
      };

    case MASTERCUSTOMERBILLTOFAILED:
      return {
        ...state,
        fetch: false,
        err: action.data,
        action: action.type,
      };

    default:
      return state;
  }
}
