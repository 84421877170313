/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/warehouse',
    exact: true,
    name: 'Warehouse',
    component: React.lazy(() => import('./views/ListWarehouse')),
  },
  {
    path: '/warehouse/create',
    exact: true,
    name: 'Create',
    component: React.lazy(() => import('./views/CreateWarehouse')),
  },
  {
    path: '/warehouse/update/:warehouseId',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdateWarehouse')),
  },
];

export default routes;