/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/inventorytransfer',
    exact: true,
    name: 'Inventory Transfer',
    component: React.lazy(() => import('./views/ListInventoryTransfer')),
  },
  {
    path: '/inventorytransfer/create',
    exact: true,
    name: 'Create',
    component: React.lazy(() => import('./views/CreateInventoryTransfer')),
  },
  {
    path: '/inventorytransfer/update/:inventoryTransferId',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdateInventoryTransfer')),
  },
  // {
  //   path: '/inventorytransfer/update/allocation/:reservationLinesId/:status',
  //   exact: true,
  //   name: 'Available Inventory',
  //   component: React.lazy(() => import('./views/AllocationDetail')),
  // },
];

export default routes;