import { APIPORT, APIPROTOCOL, APIHOST } from "../config";
import { hashFile } from ".";
import axios from 'axios';

export const handleUploadFile = async (documents: any, module: string) => {
  let result = {
    succes: false,
    message: "",
    files: [],
  };
  try {
    const maxSize = 1024 * 1024 * 30; // The size of each chunk, set to 30 Megabyte
    const chunkSize = (1024 * 1024) / 2; // The size of each chunk, set to 0,5 Megabyte
    const fileSizePerFile = 1024 * 1024 * 4; // The size of each chunk, set to 15 Megabyte
    const blobSlice = File.prototype.slice;
    const uploadDocument: any = [];
    const apiport = (APIPORT === '80') ? '' : `:${APIPORT}`;
    let totalSize = 0;
    for (let item of documents) {
      const file = item.file[0];
      totalSize += file.size; // Total number of slices
      if (file.size > fileSizePerFile) {
        result.succes = false;
        result.message = "File size Cannot more 4MB";
        return result;
      }
    }
    if (totalSize > maxSize) {
      result.succes = false;
      result.message = "File size cannot more 30MB";
      return result;
    }
    for (let item of documents) {
      const file = item.file[0];
      // const message = `Process Upload File ${item.title} : ${file.name}`;
      // const paramLoading = messageLoadingParam(message);
      // this.setState({
      //   sweetAlert: <SweetAlert param={paramLoading.param} onConfirm={() => {}} onCancel={() => {}} />,
      //   isAlert: false,
      // });

      const blockCount = Math.ceil(file.size / chunkSize); // Total number of slices
      const axiosPromiseArray = []; // axiosPromise array
      const hash: any = await hashFile(file); //File hash 
      // After obtaining the file hash, if breakpoint continuation is required, it can be checked in the background based on the hash value.
      // See if the file has been uploaded and if the transfer is complete and the slices have been uploaded.
      const newDate = new Date();
      for (let i = 0; i < blockCount; i++) {
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        // Build a form
        const form = new FormData();
        form.append('file', blobSlice.call(file, start, end));
        form.append('name', file.name);
        form.append('total', blockCount.toString());
        form.append('index', i.toString());
        form.append('size', file.size);
        form.append('modules', module);
        form.append('date', newDate.toString());
        form.append('hash', hash ? hash.toString() : "");
        // ajax submits a slice, where content-type is multipart/form-data
        const axiosOptions = {
          onUploadProgress: (e: any) => {
            // Progress in processing uploads
            console.log(blockCount, i, e, file);
          },
        };
        // Add to Promise Array
        const url = 'outbound/file/upload';
        const apiport = (APIPORT === '80') ? '' : `:${APIPORT}`;
        const path = url.includes('https://') || url.includes('http://') ? url : `${APIPROTOCOL}://${APIHOST}${apiport}/${url}`;
        axiosPromiseArray.push(axios.post(path, form, axiosOptions));
      };

      // Request merge of slice files after all slice uploads
      await axios.all(axiosPromiseArray).then(async () => {
        const data = {
          size: file.size,
          name: file.name,
          total: blockCount,
          contenttype: file.type,
          date: newDate,
          modules: module,
          hash
        };
        const url = 'outbound/file/mergechunks';
        const path = url.includes('https://') || url.includes('http://') ? url : `${APIPROTOCOL}://${APIHOST}${apiport}/${url}`;
        await axios.post(path, data)
          .then((res: any) => {
            const response = res;
            if (response.status === 200 && response.data) {
              uploadDocument.push({
                title: item.title,
                isMainImage: item.isMainImage,
                url: response.data.message.url,
                originalName: file.name,
                fileName: response.data.message.filename,
                modules: module,
              });
              result.succes = true;
              result.message = "";
            }
            else {
              result.succes = false;
              result.message = response.data.message.message;
            }
          })
          .catch(err => {
            result.succes = false;
            result.message = err.message;
          });
      });
      if (!result.succes) {
        break;
      }
    }
    if(result.succes)
    {
      result.files = uploadDocument;
    }
    return result;
  } catch (e) {
    console.log('error at Upload File with error: ', e.message);
    return {
      succes: false,
      message: e.message,
    };
  }
}

export const getContentType = (string: string): string => {

  switch (string) {

    case '.jpeg':
      return 'image/jpeg';

    case '.jpg':
      return 'image/jpg';

    case '.png':
      return 'image/png';

    case '.svg':
      return 'image/svg+xml';

    case '.pdf':
      return 'application/pdf';

    case '.doc':
      return 'application/msword';

    case '.docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    case '.xls':
      return 'application/vnd.ms-excel';

    case '.xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    default:
      return '';
  }

};