export const initialState = {
  fetch: false,
  data: null,
  res: null,
  err: null,
  disassemblyByOwner: {
    warehouseByOwner: [],
    locationByOwner: [],
    productByOwner: [],
    ajuNumber: [],
  },
  disassemblyLines: [],
  disassemblyDetail: [],
  bomHeader: [],
  disassemblyAllocate: [],
  packageDimension: [],
};

export const initialAction = {
  type: 'DEFAULT',
  data: null,
};

export const moduleCodes = {
  // Dashboard
  Dashboard: 'Dashboard',
  // Master Data
  MasterData: 'Master Data',
  MasterBillOfMaterial: 'Master Bill of Material',
  MasterWarehouse: 'Master Warehouse',
  MasterLocation: 'Master Location',
  MasterSkuProduct: 'Master SKU/Product',
  MasterOwner: 'Master Owner',
  MasterZone: 'Master Zone',
  MasterSkuProductGroup: 'Master SKU/Product Group',
  MasterSupplier: 'Master Supplier',
  MasterUOM: 'Master Unit of Measure (UOM)',
  MasterCustomsDocument: 'Master Document Pabean',
  MasterStorage: 'Master Storage Type',
  MasterGoodsStatus: 'Master Goods Status',
  MasterLocationProduct: 'Master Project Location',
  // Inventory
  Inventory: 'Inventory',
  InventoryAnalyzer: 'Inventory Analyzer',
  InventoryTransfer: 'Inventory Transfer',
  InventoryReplenishment: 'Inventory Replenishment',
  InventoryAdjustment: 'Inventory Adjustment',
  CycleCount: 'Cycle Count',
  AssemblyDissassembly: 'Assembly/Disassembly',
  // Inbound
  Inbound: 'Inbound',
  ASN: 'ASN',
  Receiving: 'Receiving',
  Putaway: 'Putaway',
  // Outbound
  Planning: 'Planning',
  Outbound: 'Outbound',
  Reservation: 'Reservation',
  Picking: 'Picking',
  Packing: 'Packing',
  GoodsIssue: 'Goods Issue',
  // Report
  Report: 'Report',
  ASNReport: 'ASN Report',
  AssemDisassemReport: 'Assembly/Disassembly Report',
  ReportMutasi: 'Report Mutasi',
  GoodsReceiptReport: 'Goods Receipt Report',
  ReportInventoryTransfer: 'Report Inventory Transfer',
  GoodsIssueReport: 'Goods Issue Report',
  ReportStockOnhand: 'Report Stock Onhand',
};
