import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERUOMINVENTORYENDPOINT,
  MASTERUOMINVENTORYFETCH,
} from '../../config';
import {
  masterUomInventorySuccess,
  masterUomInventoryFailed,
} from '.';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterUomInventory(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERUOMINVENTORYENDPOINT, null, headers);

    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.uomCode} - ${item.description}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterUomInventorySuccess(result));
    } else {
      yield put(masterUomInventoryFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterUomInventoryFailed(error.message));
  }
}
 
export const watcherSagaMasterUomInventory = [
  takeLatest(MASTERUOMINVENTORYFETCH, workerSagaMasterUomInventory),
];
