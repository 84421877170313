/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/planning',
    exact: true,
    name: 'Planning',
    component: React.lazy(() => import('./views/ListPlanning')),
  },
  {
    path: '/planning/create',
    exact: true,
    name: 'Create',
    component: React.lazy(() => import('./views/CreatePlanning')),
  },
  {
    path: '/planning/update/:planningId/:status',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdatePlanning')),
  },
  {
    path: '/planning/update/allocation/:planningLinesId/:status',
    exact: true,
    name: 'Available Inventory',
    component: React.lazy(() => import('./views/AllocationDetail')),
  },
];

export default routes;
