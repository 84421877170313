/**
 * @author: dwi.setiyadi@gmail.com
 */

import React from "react";

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
  },
  {
    path: "/replenishment",
    exact: true,
    name: "Replenishment",
    component: React.lazy(
      () => import("./views/ListReplenishment")
    ),
  },
  {
    path: "/replenishment/create",
    exact: true,
    name: "Create",
    component: React.lazy(
      () => import("./views/CreateReplenishment")
    ),
  },
  {
    path: "/replenishment/update/:replenishmentId",
    exact: true,
    name: "Update",
    component: React.lazy(
      () => import("./views/UpdateReplenishment")
    ),
  },
  // {
  //   path: '/inventorytransfer/update/allocation/:reservationLinesId/:status',
  //   exact: true,
  //   name: 'Available Inventory',
  //   component: React.lazy(() => import('./views/AllocationDetail')),
  // },
];

export default routes;
