/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/goodsissue',
    exact: true,
    name: 'Goods Issue',
    component: React.lazy(() => import('./views/ListGoodsIssue')),
  },
  {
    path: '/goodsissue/create',
    exact: true,
    name: 'Create',
    component: React.lazy(() => import('./views/CreateGoodsIssue')),
  },
  {
    path: '/goodsissue/update/:goodsIssueId',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdateGoodsIssue')),
  },
];

export default routes;