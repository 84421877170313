/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/report/asn',
    exact: true,
    name: 'ASN Report',
    component: React.lazy(() => import('./asn-report/views/FilterReport')),
  },
  {
    path: '/report/asdis',
    exact: true,
    name: 'Assembly/Disassembly Report',
    component: React.lazy(() => import('./assem-disassem-report/views/FilterReport')),
  },
  {
    path: '/report/goodsreceipt',
    exact: true,
    name: 'Goods Receipt Report',
    component: React.lazy(() => import('./goods-receipt-report/views/FilterReport')),
  },
  {
    path: '/report/transferinventory',
    exact: true,
    name: 'Inventory Transfer Report',
    component: React.lazy(() => import('./inventory-transfer/views/FilterReport')),
  },
  {
    path: '/report/goodsissue',
    exact: true,
    name: 'Goods Issue Report',
    component: React.lazy(() => import('./goods-issue-report/views/FilterReport')),
  },
  {
    path: '/report/stockonhand',
    exact: true,
    name: 'Stock On Hand Report',
    component: React.lazy(() => import('./stock-on-hand/views/FilterReport')),
  },
  {
    path: '/report/mutasi',
    exact: true,
    name: 'Mutation Report',
    component: React.lazy(() => import('./mutasi/views/FilterReport')),
  },
  {
    path: '/report/mutationhistory',
    exact: true,
    name: 'Mutation Report',
    component: React.lazy(() => import('./mutation-history/views/FilterReport')),
  },
];

export default routes;