import { Configuration, ILoggerCallback, LogLevel } from "@azure/msal-browser";
import { CLIENT_ID, CLIENT_ID_B2C, LANDINGHOST, REDIRECT_URI, SIGN_IN_URL, SIGN_IN_URL_B2C } from "../config/env";

const knownAuthorities = [
    SIGN_IN_URL,
    SIGN_IN_URL_B2C
]

export class AuthConfig {
  pcaConfig: Configuration;
  b2cConfig: Configuration;

  constructor() {
    this.pcaConfig = {
        auth: {
            clientId: CLIENT_ID,
            authority: SIGN_IN_URL,
            redirectUri: REDIRECT_URI,
            knownAuthorities: knownAuthorities,
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: 'https://' + LANDINGHOST,
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
                loggerCallback: this.handleCallback
            },
        },
    };
    
    this.b2cConfig = {
        auth: {
            clientId: CLIENT_ID_B2C,
            authority: SIGN_IN_URL_B2C,
            redirectUri: REDIRECT_URI,
            knownAuthorities: knownAuthorities,
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: 'https://' + LANDINGHOST,
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
                loggerCallback: this.handleCallback
            },
        },
    };
  }

  handleCallback = (level: any, message: any, containsPii: any) : ILoggerCallback | void => {
    if (containsPii) {
        return;
    }
    switch (level) {
        case LogLevel.Error:
            console.error(message);
            return;
        case LogLevel.Info:
            console.info(message);
            return;
        case LogLevel.Verbose:
            console.debug(message);
            return;
        case LogLevel.Warning:
            console.warn(message);
            return;
    }
  }
}