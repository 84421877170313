import {
  Action,
  State,
} from '../../../../config/interfaces';

import {
  initialState,
  initialAction,
} from '../../../../config';

import {
  MASTERSTORAGEFETCH,
  MASTERSTORAGESUCCESS,
  MASTERSTORAGEFAILED,
} from '../../config';

export function ReducerMasterStorage(state: State = initialState, action: Action = initialAction) {
  switch (action.type) {
    case MASTERSTORAGEFETCH:
      return {
        ...state,
        fetch: true,
        data: action.data,
        action: action.type,
      };

    case MASTERSTORAGESUCCESS:
      return {
        ...state,
        fetch: false,
        res: action.data,
        action: action.type,
      };

    case MASTERSTORAGEFAILED:
      return {
        ...state,
        fetch: false,
        err: action.data,
        action: action.type,
      };

    default:
      return state; 
  }
}