import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERCUSTOMSDOCUMENTINVENTORYENDPOINT,
  MASTERCUSTOMSDOCUMENTINVENTORYFETCH,
} from '../../config';
import {
  masterCustomsDocumentInventorySuccess,
  masterCustomsDocumentInventoryFailed,
} from './ActionMasterCustomsDocument';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterCutomsDocument(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERCUSTOMSDOCUMENTINVENTORYENDPOINT, null, headers);

    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.customsCode} - ${item.description}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterCustomsDocumentInventorySuccess(result));
    } else {
      yield put(masterCustomsDocumentInventoryFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterCustomsDocumentInventoryFailed(error.message));
  }
}
 
export const watcherSagaMasterCustomsDocumentInventory = [
  takeLatest(MASTERCUSTOMSDOCUMENTINVENTORYFETCH, workerSagaMasterCutomsDocument),
];
