/**
 * @author: dwi.setiyadi@gmail.com
*/

import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import reduxReducer from './ReduxReducer';
import reduxSaga from './ReduxSaga';
import { ReduxPersist } from '../config';

let finalReducers: any = reduxReducer;
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

if (ReduxPersist.active) {
  const persistConfig = ReduxPersist.storeConfig;
  finalReducers = persistReducer(persistConfig, reduxReducer);
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const middlewares = [sagaMiddleware];

const reduxStore = createStore(
  finalReducers,
  composeEnhancer(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(reduxSaga);

const Persistor = persistStore(reduxStore);
const Store = reduxStore;

export const SET_JWT_TOKEN = 'SET_JWT_TOKEN';
export const SET_JWT_TOKEN_EXP = 'USERLOSET_JWT_TOKEN_EXPGINSUCCESS';

const publicReducer = (state = {JWT_TOKEN: null, JWT_TOKEN_EXP: null}, action : any) => {
  switch (action.type) {
    case SET_JWT_TOKEN:
      return {
        ...state,
        JWT_TOKEN: action.data,
      }
    case SET_JWT_TOKEN_EXP:
      return {
        ...state,
        JWT_TOKEN_EXP: action.data,
      }
    default:
      return state;
  }
}

const PublicStore = createStore(publicReducer);

export {
  Persistor,
  Store,
  PublicStore,
};
