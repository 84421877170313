import { HttpService } from "./HttpService";

/* #region INBOUND  */

export const locationForPutaway = (headers: any, isCheckFreeze: boolean) =>
  new Promise(async (resolve, reject) => {
    try {
      let listLocation: object[] = [];

      const res = await HttpService.get(
        `inbound/location/putaways`,
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.locationName} - ${item.locationName}`,
          };
          if (isCheckFreeze) {
            if (!item.isTransactionFreeze) {
              result.push(data);
            }
          } else {
            result.push(data);
          }
          data = {};
        });
        listLocation = [
          { locationId: "", locationName: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listLocation);
    } catch (e) {
      console.log("error at get request locationForPutaway with error: ", e);
      reject(e);
    }
  });

export const goodsStatusAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let goodsStatus: object[] = [];

      const res = await HttpService.get(`inbound/goodsstatuses`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.goodsStatusCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        goodsStatus = [
          { goodsStatusId: "", goodsStatusCode: "", codeName: "Select" },
          ...result,
        ];
      }

      resolve(goodsStatus);
    } catch (e) {
      console.log("error at get request goodsStatusAll with error: ", e);
      reject(e);
    }
  });

export const packageTypeAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listPackageType: object[] = [];

      const res = await HttpService.get(`inbound/packagetypes`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.packageTypeCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        listPackageType = [
          { packageTypeId: "", packageTypeCode: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listPackageType);
    } catch (e) {
      console.log("error at get request skuProductGroupAll with error: ", e);
      reject(e);
    }
  });

export const uomAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listUom: object[] = [];

      const res = await HttpService.get(`inbound/uoms`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.uomCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        listUom = [{ uomId: "", uomCode: "", codeName: "Select" }, ...result];
      }

      resolve(listUom);
    } catch (e) {
      console.log("error at get request skuProductGroupAll with error: ", e);
      reject(e);
    }
  });

export const locationAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listLocation: object[] = [];

      const res = await HttpService.get(`inbound/locations`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.locationName} - ${item.locationName}`,
          };

          result.push(data);
          data = {};
        });
        listLocation = [
          { locationId: "", locationName: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listLocation);
    } catch (e) {
      console.log("error at get request locationAll with error: ", e);
      reject(e);
    }
  });

export const currencyAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listCurrency: object[] = [];

      const res = await HttpService.get(`inbound/currencies`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.currencyCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        listCurrency = [
          { currencyId: "", currencyCode: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listCurrency);
    } catch (e) {
      console.log("error at get request currencyAll with error: ", e);
      reject(e);
    }
  });

export const skuProductAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listSkuProduct: object[] = [];

      const res = await HttpService.get(`inbound/skuproducts`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.productCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        listSkuProduct = [
          { productId: "", productCode: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listSkuProduct);
    } catch (e) {
      console.log("error at get request skuProductAll with error: ", e);
      reject(e);
    }
  });

export const skuProductGroupAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listSkuProductGroup: object[] = [];

      const res = await HttpService.get(
        `inbound/skuproductgroups`,
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.productGroupCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        listSkuProductGroup = [
          {
            productGroupId: "",
            productGroupCode: "Select",
            codeName: "Select",
          },
          ...result,
        ];
      }

      resolve(listSkuProductGroup);
    } catch (e) {
      console.log("error at get request skuProductGroupAll with error: ", e);
      reject(e);
    }
  });

export const supplierAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listSupplier: object[] = [];

      const res = await HttpService.get(`inbound/suppliers`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.supplierCode} - ${item.supplierName}`,
          };

          result.push(data);
          data = {};
        });
        listSupplier = [
          { supplierId: "", supplierCode: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listSupplier);
    } catch (e) {
      console.log("error at get request supplierAll with error: ", e);
      reject(e);
    }
  });

export const customsdocumentAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listcustomsdocument: object[] = [];

      const res = await HttpService.get(
        `inbound/customsdocuments`,
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.customsCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        listcustomsdocument = [
          { customsDocumentId: "", customsCode: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listcustomsdocument);
    } catch (e) {
      console.log("error at get request customsdocumentAll with error: ", e);
      reject(e);
    }
  });

export const customerAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listCustomer: object[] = [];

      const res = await HttpService.get(`inbound/customers`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.customerCode} - ${item.customerName}`,
          };

          result.push(data);
          data = {};
        });
        listCustomer = [
          { customerId: "", customerCode: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listCustomer);
    } catch (e) {
      console.log("error at get request customerAll with error: ", e);
      reject(e);
    }
  });

export const receivingStatusAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let receivingStatus: object[] = [];

      const res = await HttpService.get(
        `inbound/receivingstatuses`,
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.statusCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        receivingStatus = [
          { statusId: "", statusCode: "", codeName: "Select" },
          ...result,
        ];
      }

      resolve(receivingStatus);
    } catch (e) {
      console.log("error at get request receivingStatusAll with error: ", e);
      reject(e);
    }
  });

export const containerTypeAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let containerTypes: object[] = [];

      const res = await HttpService.get(
        `inbound/containertypes`,
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.containertypeCode} - ${item.containertypeName}`,
          };

          result.push(data);
          data = {};
        });
        containerTypes = [
          { containertypeId: "", containertypeCode: "", codeName: "Select" },
          ...result,
        ];
      }

      resolve(containerTypes);
    } catch (e) {
      console.log("error at get request containerTypeAll with error: ", e);
      reject(e);
    }
  });

export const previousAjuNumberReceivingAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listPreviousAjuNumber: object[] = [];

      const res = await HttpService.get(
        "inbound/receiving/previousajunumber",
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          if (item.previousAjuNumber) {
            result.push(item);
          }
        });
        listPreviousAjuNumber = [...result];
      }
      resolve(listPreviousAjuNumber);
    } catch (e) {
      console.log("Error get previousajunumber: ", e);
      reject(e);
    }
  });

export const previousNopenReceivingAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listPreviousNopen: object[] = [];

      const res = await HttpService.get(
        "inbound/receiving/previousnopen",
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          if (item.previousNopen) {
            result.push(item);
          }
        });
        listPreviousNopen = [...result];
      }
      resolve(listPreviousNopen);
    } catch (e) {
      console.log("Error get previousNopen: ", e);
      reject(e);
    }
  });

export const masterTypesAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listMasterTypes: object[] = [];

      const res = await HttpService.get(
        `inbound/master-types-all`,
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.masterTypes.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.code} - ${item.name}`,
          };

          result.push(data);
          data = {};
        });
        listMasterTypes = [{ id: "", code: "", codeName: "Select" }, ...result];
      }

      resolve(listMasterTypes);
    } catch (e) {
      console.log(
        "error at get request projectsByOrganization with error: ",
        e
      );
      reject(e);
    }
  });

export const warehouseAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listWarehouses: object[] = [];

      const res = await HttpService.get(`inbound/warehouses`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.warehouseCode} - ${item.warehouseName}`,
          };

          result.push(data);
          data = {};
        });
        listWarehouses = [
          { warehouseId: "", warehouseCode: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listWarehouses);
    } catch (e) {
      console.log("error at get request warehouseAll with error: ", e);
      reject(e);
    }
  });

export const warehouseByOwner = (ownerId: string, headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listWarehouses: object[] = [];

      const res = await HttpService.get(
        `inbound/warehouses/${ownerId}/ownerId`,
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.warehouseCode} - ${item.warehouseName}`,
          };

          result.push(data);
          data = {};
        });
        listWarehouses = [
          { warehouseId: "", warehouseCode: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listWarehouses);
    } catch (e) {
      console.log("error at get request warehouseAll with error: ", e);
      reject(e);
    }
  });
/* #endregion */

/* #region OUTBOUND  */

export const pickingStrategyList = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let pickingStrategyList: object[] = [];
      const res = await HttpService.get(
        `outbound/reservation/getPickingStrategy`,
        null,
        headers
      );
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.allocationPickingStrategyCode} - ${item.description}`,
          };
          result.push(data);
          data = {};
        });
        pickingStrategyList = [
          {
            allocationPickingStrategyId: "",
            allocationPickingStrategyCode: "Select",
            description: "Select",
          },
          ...result,
        ];
      }
      resolve(pickingStrategyList);
    } catch (e) {
      console.log("error at get request pickingList with error: ", e);
      reject(e);
    }
  });

/* #endregion */

/* #region INVENTORY  */

export const InventoryUomAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listUom: object[] = [];

      const res = await HttpService.get(`inventory/uom/all`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.uomCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        listUom = [{ uomId: "", uomCode: "", codeName: "Select" }, ...result];
      }

      resolve(listUom);
    } catch (e) {
      console.log("error at get request InventoryUomAll with error: ", e);
      reject(e);
    }
  });

export const InventorySkuAll = (headers: any) =>
  new Promise(async (resolve, reject) => {
    try {
      let listSkuProduct: object[] = [];

      const res = await HttpService.get(`inventory/skuproducts`, null, headers);
      const result: object[] = [];

      if (res.status === 200 && res.data) {
        res.data.message.forEach((item: any) => {
          let data = {
            ...item,
            codeName: `${item.productCode} - ${item.description}`,
          };

          result.push(data);
          data = {};
        });
        listSkuProduct = [
          { productId: "", productCode: "Select", codeName: "Select" },
          ...result,
        ];
      }

      resolve(listSkuProduct);
    } catch (e) {
      console.log("error at get request skuProductAll with error: ", e);
      reject(e);
    }
  });

export const locationProjectAll = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listLocationProject: object[] = [];

        const res = await HttpService.get(`inventory/location/locationProject`, null, headers);
        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.locationProjectName} - ${item.locationProjectName}`,
                    nameCode: `${item.locationProjectCode} - ${item.locationProjectName}`
                }

                result.push(data);
                data = {};
            });
            listLocationProject = [{ locationProjectId: "", locationProjectName: "Select", codeName: "Select" }, ...result];
        }

        resolve(listLocationProject);
    } catch (e) {
        console.log('error at get request locationAll with error: ', e);
        reject(e);
    }
});

export const goodsStatusInv = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let goodsStatus: object[] = [];

        const res = await HttpService.get(`inventory/goodsstatus/all`, null, headers);
        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    description: `${item.goodsStatusCode} - ${item.description}`
                }

                result.push(data);
                data = {};
            });
            goodsStatus = [{ goodsStatusId: "", goodsStatusCode: "", description: "Select" }, ...result];
            // goodsStatus = [ ...result];
        }

        resolve(goodsStatus);
    } catch (e) {
        console.log('error at get request goodsStatusAll with error: ', e);
        reject(e);
    }
});

export const ownerWarehouseAll = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listWarehouses: object[] = [];

        // const res = await HttpService.get(`inventory/owner/all`, null, headers);
        const res = await HttpService.get(`inventory/master/owner/all`, null, headers);

        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.ownerCode} - ${item.description}`
                }

                result.push(data);
                data = {};
            });
            listWarehouses = [{ ownerId: "", ownerCode: "Select", codeName: "Select" }, ...result];
        }

        resolve(listWarehouses);
    } catch (e) {
        console.log('error at get request ownerWarehouseAll with error: ', e);
        reject(e);
    }
});

export const getAllOwner = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listWarehouses: object[] = [];

        const res = await HttpService.get(`inventory/master/owner/all`, null, headers);

        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.ownerCode} - ${item.description}`
                }

                result.push(data);
                data = {};
            });
            listWarehouses = [{ ownerId: "", ownerCode: "Select", codeName: "Select" }, ...result];
        }

        resolve(listWarehouses);
    } catch (e) {
        console.log('error at get request ownerWarehouseAll with error: ', e);
        reject(e);
    }
});

export const previousAjuNumberInventoryAll = (data: any, headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listPreviousAjuNumber: object[] = [];

        const res = await HttpService.get('inventory/inventory/ajunumbers', null, headers);
        const result: object[] = [];
        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                if (item.ajuNumber) {
                    result.push({ previousAjuNumber: item.ajuNumber });
                }
            });
            listPreviousAjuNumber = [...result];
        }
        resolve(listPreviousAjuNumber);
    } catch (e) {
        console.log('Error get previousajunumber: ', e);
        reject(e);
    }
});


export const previousAjuNumberInventoryAllList = (data: any, headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listPreviousAjuNumber: object[] = [];

        const res = await HttpService.get('inventory/inventory/ajunumbersNew', data, headers);
        const result: object[] = [];
        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                if (item.nopen && item.ajuNumber) {
                    result.push({ previousNopen: item.nopen, previousAjuNumber: item.ajuNumber, codeName: `${item.ajuNumber} | ${item.nopen}` });
                }
            });
            listPreviousAjuNumber = [...result];
        }
        resolve(listPreviousAjuNumber);
    } catch (e) {
        console.log('Error get previousajunumber: ', e);
        reject(e);
    }
});

export const ownerAll = (data: any, headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listAllOwner: object[] = [];

        const res = await HttpService.get('inventory/master/owner/all', null, headers);
        const result: object[] = [];
        if (res.status === 200 && res.data) {

            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.ownerCode} - ${item.description}`
                }

                result.push(data);
                data = {};
            });
            listAllOwner = [{ ownerId: "", ownerCode: "Select", codeName: "Select" }, ...result];
            // listAllOwner = [...result];
        }
        resolve(listAllOwner);
    } catch (e) {
        console.log('Error get previousajunumber: ', e);
        reject(e);
    }
});

export const allWarehouses = (data: any, headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listAllOwner: object[] = [];

        const res = await HttpService.get('inventory/warehouses/all', null, headers);
        const result: object[] = [];
        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.warehouseCode} - ${item.warehouseName}`
                }

                result.push(data);
                data = {};
            });
            listAllOwner = [{ warehouseId: "", ownerId: "", warehouseCode: "Select", codeName: "Select" }, ...result];
        }
        resolve(listAllOwner);
    } catch (e) {
        console.log('Error get allWarehouses: ', e);
        reject(e);
    }
});

export const previousNopenInventoryAll = (data: any, headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listPreviousNopen: object[] = [];
        const res = await HttpService.get('inventory/inventory/nopens', data, headers);
        const result: object[] = [];


        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                if (item.nopen) {
                    result.push({ previousNopen: item.nopen });
                }
            });
            listPreviousNopen = [...result];
        }
        resolve(listPreviousNopen);
    } catch (e) {
        console.log('Error get previousNopen: ', e);
        reject(e);
    }
});

export const previouseNopenInventoryAllNew = (data: any, headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listPreviousNopen: object[] = [];

        const res = await HttpService.get('inventory/inventory/nopensNew', data, headers);
        const result: object[] = [];


        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                if (item.nopen && item.ajuNumber) {
                    result.push({ previousNopen: item.nopen, previousAjuNumber: item.ajuNumber, codeName: `${item.ajuNumber} | ${item.nopen}` });
                }
            });
            listPreviousNopen = [...result];
        }
        resolve(listPreviousNopen);
    } catch (e) {
        console.log('Error get previousNopen: ', e);
        reject(e);
    }
});

export const zoneAll = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listZone: object[] = [];

        const res = await HttpService.get(`inventory/master/zone`, null, headers);
        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.zoneCode} - ${item.description}`
                }

                result.push(data);
                data = {};
            });
            listZone = [{ zoneId: "", zoneCode: "Select", description: "Select" }, ...result];
            // listZone = [ ...result];
        }

        resolve(listZone);
    } catch (e) {
        console.log('error at get request locationAll with error: ', e);
        reject(e);
    }
});
export const storageAll = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listStorage: object[] = [];

        const res = await HttpService.get(`inventory/master/storage`, null, headers);
        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.storageCode} - ${item.description}`
                }

                result.push(data);
                data = {};
            });
            listStorage = [{ storageId: "", storageCode: "Select", description: "Select" }, ...result];
            // listStorage = [ ...result];
        }

        resolve(listStorage);
    } catch (e) {
        console.log('error at get request locationAll with error: ', e);
        reject(e);
    }
});

export const locationDropDown = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listLocationDropdown: object[] = [];

        const res = await HttpService.get(`inventory/locationbin/dropdown`, null, headers);
        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.ownerCode} - ${item.description}`
                }

                result.push(data);
                data = {};
            });
            listLocationDropdown = [{ locationId: "", locationCode: "Select", warehouseCode: "Select", warehouseId: "" }, ...result];
        }

        resolve(listLocationDropdown);
    } catch (e) {
        console.log('error at get request ownerWarehouseAll with error: ', e);
        reject(e);
    }
});

export const customerInventoryAll = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listCustomer: object[] = [];

        const res = await HttpService.get(`inventory/customer/all`, null, headers);
        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.customerCode} - ${item.customerName}`
                }

                result.push(data);
                data = {};
            });
            listCustomer = [{ customerId: "", customerCode: "Select", codeName: "Select" }, ...result];
        }

        resolve(listCustomer);
    } catch (e) {
        console.log('error at get request customerInventoryAll with error: ', e);
        reject(e);
    }
});

export const customsdocumentInvAll = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listcustomsdocument: object[] = [];

        const res = await HttpService.get(`inventory/customsdocument/all`, null, headers);
        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.customsCode} - ${item.description}`
                }

                result.push(data);
                data = {};
            });
            listcustomsdocument = [{ customsDocumentId: "", customsCode: "Select", codeName: "Select" }, ...result];
        }

        resolve(listcustomsdocument);
    } catch (e) {
        console.log('error at get request customsdocumentInvAll with error: ', e);
        reject(e);
    }
});

export const supplierInventoryAll = (headers: any) => new Promise(async (resolve, reject) => {
    try {
        let listSupplier: object[] = [];

        const res = await HttpService.get(`inventory/master/supplier/list`, null, headers);
        const result: object[] = [];

        if (res.status === 200 && res.data) {
            res.data.message.forEach((item: any) => {
                let data = {
                    ...item,
                    codeName: `${item.supplierCode} - ${item.supplierName}`
                }

                result.push(data);
                data = {};
            });
            listSupplier = [{ supplierId: "", supplierCode: "Select", codeName: "Select" }, ...result];
        }

        resolve(listSupplier);
    } catch (e) {
        console.log('error at get request supplierAll with error: ', e);
        reject(e);
    }
});

export const skuProductByFilter = (headers: any, filter: any) => new Promise(async (resolve, reject) => {
    try {
        let listSkuProduct: object[] = [];

        const res = await HttpService.get('inventory/skuproducts/filter', filter, headers);

        if (res.status === 200 && res.data) {
            const objProducts = res.data.message;

            listSkuProduct = objProducts.map((product: any) => {
                const description = product.description && product.description.length > 30 ?
                    product.description.toString().substring(0, 30) : product.description;
                return (
                    ({
                        value: product.productId,
                        label: !product.description ? "Select" : product.productCode ?
                            `${product.productCode} (${product.generatedProductCode}) - ${description}`
                            : `${product.generatedProductCode} - ${description}`
                    })
                )
            });
        }

        resolve(listSkuProduct);
    } catch (e) {
        console.log('error at get request skuProductByFilter with error: ', e);
        reject(e);
    }
});

export const skuProductById = (headers: any, skuProductId: any) => new Promise(async (resolve, reject) => {
    try {
        if (skuProductId) {
            const res = await HttpService.get(`inventory/skuproduct/${skuProductId}`, null, headers);

            if (res.status === 200 && res.data) {
                resolve(res.data.message);
            }
        }
        resolve(null);
    } catch (e) {
        console.log('error at get request skuProductById with error: ', e);
        reject(e);
    }
});

export const skuProductByIdWithDoc = (headers: any, skuProductId: any) => new Promise(async (resolve, reject) => {
    try {
        if (skuProductId) {
            const res = await HttpService.get(`inventory/master/skuproduct/${skuProductId}`, null, headers);

            if (res.status === 200 && res.data) {
                resolve(res.data.message);
            }
        }
        resolve(null);
    } catch (e) {
        console.log('error at get request skuProductByIdWithDoc with error: ', e);
        reject(e);
    }
});


export const skuProductByFilterTraceAndTrack = (headers: any, filter: any) => new Promise(async (resolve, reject) => {
  try {
      let listSkuProduct: object[] = [];

      const res = await HttpService.get('inventory/skuproducts/filter', filter, headers);

      if (res.status === 200 && res.data) {
          const objProducts = res.data.message;

          listSkuProduct = objProducts.map((product: any) => {
              const description = product.description;
              const generatedProductCode = product.generatedProductCode
              return (
                  ({
                      value: product.generatedProductCode,
                      label: !product.description ? "Select" : product.productCode ?
                          `${product.productCode} (${generatedProductCode}) - ${description}`
                          : `${generatedProductCode} - ${description}`
                  })
              )
          });
      }

      resolve(listSkuProduct);
  } catch (e) {
      console.log('error at get request skuProductByFilter with error: ', e);
      reject(e);
  }
});


/* #endregion */
