import axios from 'axios';
import jwt_decode from "jwt-decode";
import { PublicStore, 
  SET_JWT_TOKEN
} from '../bootstrap/ReduxStore';

import {
  APIHOST,
  APIPORT,
  APIPROTOCOL,
} from '../config';
import authService from './AuthService';

const resTimeout: string =
  'Looks like the server is taking too long to respond, this can be caused by either poor connectivity or an error with our server. Please try again in a while';

const getRequestOptions = (method: string, data: any, headers: any, reqOptions: any) => {
  const parseHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  }

  try {
    let requestOptions: any = {
      crossDomain: true,
      method,
      headers: parseHeaders,
      data: data ? data : '',
      timeout: 60000,
      validateStatus: false,
    };

    if (reqOptions) {
      requestOptions = { ...requestOptions, ...reqOptions };
    }

    if (method === 'GET') {
      delete requestOptions.data;
      if (data) {
        requestOptions.params = { ...data };
      }
    }
    return requestOptions;
  } catch (e) {
    const errorMessage: string = `Something went wrong when preparing request options to fetch data: ${e}`;
    return errorMessage;
  }
}

const refreshToken = async (requestOptions: any) => {
  let { JWT_TOKEN } = PublicStore.getState()
  let account = authService.getAccount()
  if(account.length === 0){
    return undefined
  }

  if (JWT_TOKEN !== null) {
    const decode: any = jwt_decode(JWT_TOKEN!)
    if (decode.exp * 1000 <= Date.now()) {
      let token = await authService.getTokenSilent();
      if(token){
        PublicStore.dispatch({type: SET_JWT_TOKEN, data: token.idToken})
        return token.idToken;
      }else{
        return undefined;
      }
    }
  }else{
    let token = await authService.getTokenSilent();
    if(token){
      PublicStore.dispatch({type: SET_JWT_TOKEN, data: token.idToken})
      return token.idToken;
    }else{
      return undefined;
    }
  }
  return JWT_TOKEN;
}

const doFetch = async (requestOptions: any, url: string, withnoversion?:boolean) => {
  const token = await refreshToken(requestOptions);
  requestOptions.headers["Login-Token"] = token || '';
  
  try {
    if (typeof requestOptions !== 'string') {
      const apiport = (APIPORT === '80') ? '' : `:${APIPORT}`;
      let path;
      if (withnoversion) {
        const splitto = APIHOST.split("/");
        splitto.splice(1, 1);
        const apihost = splitto.join("/");
        path =
          url.includes("https://") || url.includes("http://")
            ? url
            : `${APIPROTOCOL}://${apihost}${apiport}/${url}`;
      } else {
        path =
          url.includes("https://") || url.includes("http://")
            ? url
            : `${APIPROTOCOL}://${APIHOST}${apiport}/${url}`;
      }
      // const path = url.includes('https://') || url.includes('http://') ? url : `${APIPROTOCOL}://${APIHOST}:${APIPORT}/${url}`;
      return axios(path, requestOptions)
        .then((result: any) => {
          return result;
        })
        .catch((err) => {
          const error = JSON.parse(JSON.stringify(err));
          if (error.code === 'ECONNABORTED') {
            return { data: { message: resTimeout } };
          }
          return error;
        });
    } else {
      const errorMessage: string = requestOptions;
      return errorMessage;
    }
  } catch (e) {
    const errorMessage: string = `Something went wrong when doFetch: ${e}`;
    return errorMessage;
  }
}

export const HttpService = {
  get(url: string, data: any, headers: object, reqOptions: any = null) {
    try {
      const requestOptions: any = getRequestOptions(
        "GET",
        data,
        headers,
        reqOptions
      );
      return doFetch(requestOptions, url);
    } catch (e) {
      console.log("error at get request method with error: ", e);
      return e;
    }
  },
  post(url: string, data: any, headers: object, reqOptions: any = null) {
    try {
      const requestOptions: any = getRequestOptions(
        "POST",
        data,
        headers,
        reqOptions
      );
      return doFetch(requestOptions, url);
    } catch (e) {
      console.log("error at post request method with error: ", e);
      return e;
    }
  },
  postwithnoversion(url: string, data: any, headers: object, reqOptions: any = null) {
    try {
      const requestOptions: any = getRequestOptions(
        "POST",
        data,
        headers,
        reqOptions
      );
      return doFetch(requestOptions, url, true);
    } catch (e) {
      console.log("error at post request method with error: ", e);
      return e;
    }
  },
  put(url: string, data: any, headers: object, reqOptions: any = null) {
    try {
      const requestOptions: any = getRequestOptions(
        "PUT",
        data,
        headers,
        reqOptions
      );
      return doFetch(requestOptions, url);
    } catch (e) {
      console.log("error at put request method with error: ", e);
      return e;
    }
  },
  delete(url: string, data: any, headers: object, reqOptions: any = null) {
    try {
      const requestOptions: any = getRequestOptions(
        "DELETE",
        data,
        headers,
        reqOptions
      );
      return doFetch(requestOptions, url);
    } catch (e) {
      console.log("error at delete request method with error: ", e);
      return e;
    }
  },
};
