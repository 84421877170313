/**
 * @author: dwi.setiyadi@gmail.com
*/

/*
Startup Examples:

import { ReducerSome } from '../modules/auth/ReducerSome';
const reducers: object = {
  some: ReducerSome,
};
export default reducers;
*/

import { ReducerNowPlayingDashboard } from '../modules/dashboard/ReducerDashboard';
import { ReducerAuth } from '../modules/auth/redux';
import { ReducerReceiving, ReducerPackageDimension } from '../modules/receiving/redux/ReducerReceiving';
import { ReducerHeaderSearch } from '../components/containers/DefaultLayout/redux';
import { ReducerReservation } from '../modules/reservation/redux/ReducerReservation';
import { ReducerPlanning } from '../modules/planning/redux/ReducerPlanning';
import { ReducerDisassembly } from '../modules/assembly-disassembly/redux/ReducerDisassembly';
import {
  ReducerMasterContainerType,
  ReducerMasterCurrency,
  ReducerMasterCustomsDocument,
  ReducerMasterGoodsStatus,
  ReducerMasterLocation,
  ReducerMasterLocationProject,
  ReducerMasterOwner,
  ReducerMasterZone,
  ReducerMasterReceivingStatus,
  ReducerMasterSkuProduct,
  ReducerMasterSupplier,
  ReducerMasterUom,
  ReducerMasterWarehouse,
  ReducerMasterType,
  ReducerMasterStorage,
  ReducerMasterCustomer,
  ReducerMasterCustomerBillto,
  ReducerMasterPackageType,
  ReducerMasterSkuProductGroup,
} from '../modules/foundation/redux';

const reducers: object = {
  nowPlaying: ReducerNowPlayingDashboard,
  masterType: ReducerMasterType,
  auth: ReducerAuth,
  receiving: ReducerReceiving,
  reservation: ReducerReservation,
  currencies: ReducerMasterCurrency,
  uoms: ReducerMasterUom,
  suppliers: ReducerMasterSupplier,
  skuProducts: ReducerMasterSkuProduct,
  warehouses: ReducerMasterWarehouse,
  receivingStatuses: ReducerMasterReceivingStatus,
  owners: ReducerMasterOwner,
  locations: ReducerMasterLocation,
  locationsproject: ReducerMasterLocationProject,
  goodsStatuses: ReducerMasterGoodsStatus,
  containerTypes: ReducerMasterContainerType,
  customsDocuments: ReducerMasterCustomsDocument,
  customers: ReducerMasterCustomer,
  customersbillto: ReducerMasterCustomerBillto,
  packageTypes: ReducerMasterPackageType,
  skuProductGroups: ReducerMasterSkuProductGroup,
  disassembly: ReducerDisassembly,
  packageDimension: ReducerPackageDimension,
  planning: ReducerPlanning,
  zones: ReducerMasterZone,
  storages: ReducerMasterStorage,
  headerSearch: ReducerHeaderSearch,
};

export default reducers;
