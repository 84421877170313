/**
 * @author: dwi.setiyadi@gmail.com
 */

import React from "react";

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
  },
  {
    path: "/master/create",
    exact: true,
    name: "Create",
    component: React.lazy(() => import("./views/CreateMaster")),
  },
  {
    path: "/master/update/:masterDataId",
    exact: true,
    name: "Update",
    component: React.lazy(() => import("./views/UpdateMaster")),
  },
  {
    path: "/master/billofmaterial",
    exact: true,
    name: "Master Bill of Material",
    component: React.lazy(
      () => import("./bill-of-material/views/ListMasterBillOfMaterial")
    ),
  },
  {
    path: "/master/billofmaterial/create",
    exact: true,
    name: "Create Master Bill of Material",
    component: React.lazy(
      () => import("./bill-of-material/views/CreateMasterBillOfMaterial")
    ),
  },
  {
    path: "/master/billofmaterial/update/:billOfMaterialHeaderId",
    exact: true,
    name: "Update Master Bill of Material",
    component: React.lazy(
      () => import("./bill-of-material/views/UpdateMasterBillOfMaterial")
    ),
  },
  {
    path: "/master/skuproduct",
    exact: true,
    name: "Master SKU/Product",
    component: React.lazy(
      () => import("./sku-product/views/ListMasterSkuProduct")
    ),
  },
  {
    path: "/master/skuproduct/create",
    exact: true,
    name: "Create Master SKU/Product",
    component: React.lazy(
      () => import("./sku-product/views/CreateMasterSkuProduct")
    ),
  },
  {
    path: "/master/skuproduct/update/:productId",
    exact: true,
    name: "Update Master SKU/Product",
    component: React.lazy(
      () => import("./sku-product/views/UpdateMasterSkuProduct")
    ),
  },
  {
    path: "/master/warehouse",
    exact: true,
    name: "Master Warehouse",
    component: React.lazy(
      () => import("./warehouse/views/ListMasterWarehouse")
    ),
  },

  {
    path: "/master/warehouse/create",
    exact: true,
    name: "Create Master Warehouse",
    component: React.lazy(
      () => import("./warehouse/views/CreateMasterWarehouse")
    ),
  },
  {
    path: "/master/warehouse/update/:warehouseId",
    exact: true,
    name: "Update Master Warehouse",
    component: React.lazy(
      () => import("./warehouse/views/UpdateMasterWarehouse")
    ),
  },
  {
    path: "/master/location",
    exact: true,
    name: "Master Location",
    component: React.lazy(
      () => import("./locationbin/views/ListMasterBinLocation")
    ),
  },
  {
    path: "/master/location/create",
    exact: true,
    name: "Create Master Location",
    component: React.lazy(
      () => import("./locationbin/views/CreateMasterBinLocation")
    ),
  },
  {
    path: "/master/location/update/:locationId",
    exact: true,
    name: "Update Master Location",
    component: React.lazy(
      () => import("./locationbin/views/UpdateMasterBinLocation")
    ),
  },
  {
    path: "/master/location/view/single/:locationId",
    exact: true,
    name: "View QR Code Single",
    component: React.lazy(() => import("./locationbin/views/ViewQrCodeSingle")),
  },
  {
    path: "/master/location/view/multiple/:warehouseId",
    exact: true,
    name: "View QR Code Multiple",
    component: React.lazy(
      () => import("./locationbin/views/ViewQrCodeMultiple")
    ),
  },
  {
    path: "/master/supplier",
    exact: true,
    name: "Master Supplier",
    component: React.lazy(() => import("./supplier/views/ListMasterSupplier")),
  },

  {
    path: "/master/supplier/create",
    exact: true,
    name: "Create Master Supplier",
    component: React.lazy(
      () => import("./supplier/views/CreateMasterSupplier")
    ),
  },
  {
    path: "/master/supplier/update/:supplierId",
    exact: true,
    name: "Update Master Supplier",
    component: React.lazy(
      () => import("./supplier/views/UpdateMasterSupplier")
    ),
  },

  {
    path: "/master/owner",
    exact: true,
    name: "Master Owner",
    component: React.lazy(() => import("./owner/views/ListMasterOwner")),
  },
  {
    path: "/master/owner/create",
    exact: true,
    name: "Create Master Owner",
    component: React.lazy(() => import("./owner/views/CreateMasterOwner")),
  },
  {
    path: "/master/owner/update/:ownerId",
    exact: true,
    name: "Update Master Owner",
    component: React.lazy(() => import("./owner/views/UpdateMasterOwner")),
  },

  {
    path: "/master/skuproductgroup",
    exact: true,
    name: "Master Sku Product Group",
    component: React.lazy(
      () => import("./sku-product-group/views/ListMasterSkuProductGroup")
    ),
  },
  {
    path: "/master/skuproductgroup/create",
    exact: true,
    name: "Create Master Sku Product Group",
    component: React.lazy(
      () => import("./sku-product-group/views/CreateMasterSkuProductGroup")
    ),
  },
  {
    path: "/master/skuproductgroup/update/:productGroupId",
    exact: true,
    name: "Update Master Sku Product Group",
    component: React.lazy(
      () => import("./sku-product-group/views/UpdateMasterSkuProductGroup")
    ),
  },

  {
    path: "/master/zone",
    exact: true,
    name: "Master Zone",
    component: React.lazy(() => import("./zone/views/ListMasterZone")),
  },
  {
    path: "/master/zone/create",
    exact: true,
    name: "Create Master Zone",
    component: React.lazy(() => import("./zone/views/CreateMasterZone")),
  },
  {
    path: "/master/zone/update/:zoneId",
    exact: true,
    name: "Update Master Zone",
    component: React.lazy(() => import("./zone/views/UpdateMasterZone")),
  },

  {
    path: "/master/goodsstatus",
    exact: true,
    name: "Master Goods Status",
    component: React.lazy(
      () => import("./goodsstatus/views/ListMasterGoodsStatus")
    ),
  },
  {
    path: "/master/goodsstatus/create",
    exact: true,
    name: "Create Master Goods Status",
    component: React.lazy(
      () => import("./goodsstatus/views/CreateMasterGoodsStatus")
    ),
  },
  {
    path: "/master/goodsstatus/update/:goodsStatusId",
    exact: true,
    name: "Update Master Goods Status",
    component: React.lazy(
      () => import("./goodsstatus/views/UpdateMasterGoodsStatus")
    ),
  },

  {
    path: "/master/uom",
    exact: true,
    name: "Master UOM",
    component: React.lazy(() => import("./uom/views/ListMasterUom")),
  },
  {
    path: "/master/uom/create",
    exact: true,
    name: "Create Master UOM",
    component: React.lazy(() => import("./uom/views/CreateMasterUom")),
  },
  {
    path: "/master/uom/update/:uomId",
    exact: true,
    name: "Update Master UOM",
    component: React.lazy(() => import("./uom/views/UpdateMasterUom")),
  },

  {
    path: "/master/customsdocument",
    exact: true,
    name: "Master Customs Document",
    component: React.lazy(
      () => import("./customs-document/views/ListMasterCustomsDocument")
    ),
  },
  {
    path: "/master/customsdocument/create",
    exact: true,
    name: "Create Master Customs Document",
    component: React.lazy(
      () => import("./customs-document/views/CreateMasterCustomsDocument")
    ),
  },
  {
    path: "/master/customsdocument/update/:customsDocumentId",
    exact: true,
    name: "Update Master Customs Document",
    component: React.lazy(
      () => import("./customs-document/views/UpdateMasterCustomsDocument")
    ),
  },

  {
    path: "/master/locationproject",
    exact: true,
    name: "Master Location Project",
    component: React.lazy(
      () => import("./location-project/views/ListMasterLocationProject")
    ),
  },
  {
    path: "/master/locationproject/create",
    exact: true,
    name: "Create Master Location Project",
    component: React.lazy(
      () => import("./location-project/views/CreateMasterLocationProject")
    ),
  },
  {
    path: "/master/locationproject/update/:locationProjectId",
    exact: true,
    name: "Update Master Location Project",
    component: React.lazy(
      () => import("./location-project/views/UpdateMasterLocationProject")
    ),
  },

  {
    path: "/master/storage",
    exact: true,
    name: "Master Storage",
    component: React.lazy(() => import("./storage/views/ListMasterStorage")),
  },
  {
    path: "/master/storage/create",
    exact: true,
    name: "Create Master Storage",
    component: React.lazy(() => import("./storage/views/CreateMasterStorage")),
  },
  {
    path: "/master/storage/update/:storageId",
    exact: true,
    name: "Update Master Storage",
    component: React.lazy(() => import("./storage/views/UpdateMasterStorage")),
  },
  {
    path: "/master/mappinguserowner",
    exact: true,
    name: "Master Mapping User Owner",
    component: React.lazy(
      () => import("./mapping-owner/views/ListMasterMappingUserOwner")
    ),
  },
  {
    path: "/master/client",
    exact: true,
    name: "Master Client",
    component: React.lazy(() => import("./client/views/ListMasterClient")),
  },
  {
    path: "/master/client/create",
    exact: true,
    name: "Create Master Client",
    component: React.lazy(() => import("./client/views/CreateMasterClient")),
  },
  {
    path: "/master/client/update/:customerId",
    exact: true,
    name: "Update Master Client",
    component: React.lazy(() => import("./client/views/UpdateMasterClient")),
  },

  {
    path: "/master/planning",
    exact: true,
    name: "Reservation Planning",
    component: React.lazy(() => import("./planning/views/ListPlanning")),
  },
  {
    path: "/master/planning/create",
    exact: true,
    name: "Create Planning",
    component: React.lazy(() => import("./planning/views/CreatePlanning")),
  },
  {
    path: "/master/planning/update/:planningHeaderId",
    exact: true,
    name: "Update Planning",
    component: React.lazy(() => import("./planning/views/UpdatePlanning")),
  },
  {
    path: "/master/planning/update/:planningHeaderId/state",
    exact: true,
    name: "History Planning",
    component: React.lazy(() => import("./planning/views/HistoryPlanning")),
  },
  {
    path: "/master/planning/update/:planningHeaderId/:skuProductId",
    exact: true,
    name: "History SKU",
    component: React.lazy(() => import("./planning/views/HistorySkuPlanning")),
  },


  // {
  //   path: '/reservation/update/:reservationId/:status',
  //   exact: true,
  //   name: 'Update',
  //   component: React.lazy(() => import('./views/UpdateReservation')),
  // },
  // {
  //   path: '/reservation/update/allocation/:reservationLinesId/:status',
  //   exact: true,
  //   name: 'Available Inventory',
  //   component: React.lazy(() => import('./views/AllocationDetail')),
  // },

];

export default routes;
