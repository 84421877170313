import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERSKUPRODUCTENDPOINT,
  MASTERSKUPRODUCTFETCH,
} from '../../config';
import {
  masterSkuProductSuccess,
  masterSkuProductFailed,
} from '.';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterSkuProduct(params: Action) {
  try {

    const headers = {
      ...params.data.headers,
    };

    const response = yield call(
      HttpService.get,
      MASTERSKUPRODUCTENDPOINT,
      null,
      headers
    );

    

    if (!response.data) throw response;
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item
        };

        result.push(data);
        data = {};
      });
      yield put(masterSkuProductSuccess(result));
    } else {
      yield put(masterSkuProductFailed(response.data.res));
    }
    // yield put(masterSkuProductSuccess(result));
  } catch (error) {
    yield put(masterSkuProductFailed(error.message));
  }
}

export const watcherSagaMasterSkuProduct = [
  takeLatest(MASTERSKUPRODUCTFETCH, workerSagaMasterSkuProduct),
];
