import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERSTORAGEENDPOINT,
  MASTERSTORAGEFETCH,
} from '../../config';
import {
  masterStorageSuccess,
  masterStorageFailed,
} from './ActionMasterStorage';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterStorage(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERSTORAGEENDPOINT, null, headers);
    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.zonerCode} - ${item.description}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterStorageSuccess(result));
    } else {
      yield put(masterStorageFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterStorageFailed(error.message));
  }
}
 
export const watcherSagaMasterStorage = [
  takeLatest(MASTERSTORAGEFETCH, workerSagaMasterStorage),
];
