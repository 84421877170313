import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERSUPPLIERENDPOINT,
  MASTERSUPPLIERFETCH,
} from '../../config';
import {
  masterSupplierSuccess,
  masterSupplierFailed,
} from './ActionMasterSupplier';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterSupplier(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERSUPPLIERENDPOINT, null, headers);

    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.supplierCode} - ${item.supplierName}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterSupplierSuccess(result));
    } else {
      yield put(masterSupplierFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterSupplierFailed(error.message));
  }
}
 
export const watcherSagaMasterSupplier = [
  takeLatest(MASTERSUPPLIERFETCH, workerSagaMasterSupplier),
];
