import {
  MASTERCUSTOMERBILLTOFAILED,
  MASTERCUSTOMERBILLTOFETCH,
  MASTERCUSTOMERBILLTOSUCCESS,
} from "../../config/Constants";

export const masterCustomerbilltoFetch = (value: object) => ({
  type: MASTERCUSTOMERBILLTOFETCH,
  data: value,
});
export const masterCustomerbilltoSuccess = (value: object) => ({
  type: MASTERCUSTOMERBILLTOSUCCESS,
  data: value,
});
export const masterCustomerbilltoFailed = (value: object) => ({
  type: MASTERCUSTOMERBILLTOFAILED,
  data: value,
});
