import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERPACKAGETYPEENDPOINT,
  MASTERPACKAGETYPEFETCH,
} from '../../config';
import {
  masterPackageTypeSuccess,
  masterPackageTypeFailed,
} from '.';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterPackageType(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERPACKAGETYPEENDPOINT, null, headers);
    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.packageTypeCode} - ${item.description}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterPackageTypeSuccess(result));
    } else {
      yield put(masterPackageTypeFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterPackageTypeFailed(error.message));
  }
}
 
export const watcherSagaMasterPackageType = [
  takeLatest(MASTERPACKAGETYPEFETCH, workerSagaMasterPackageType),
];
