import { Action, State } from '../../../config/interfaces';

import {
  initialAction,
  initialState,
} from '../../../config'

import {
  RESERVATIONVALUE,
} from '../config/constant';

export function ReducerReservation(state: State = initialState, action: Action = initialAction) {
  switch (action.type) {
    case RESERVATIONVALUE:
      return {
        ...state,
        fetch: true,
        data: action.data,
      };

    default:
      return state;
  }
}
