/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/picking',
    exact: true,
    name: 'Picking',
    component: React.lazy(() => import('./views/ListPicking')),
  },
  {
    path: '/picking/detail/:reservationNumber',
    exact: true,
    name: 'Picking Detail',
    component: React.lazy(() => import('./views/DetailPicking')),
  },
  // {
  //   path: '/reservation/update/:reservationId/:status',
  //   exact: true,
  //   name: 'Update',
  //   component: React.lazy(() => import('./views/UpdateReservation')),
  // },
  // {
  //   path: '/reservation/update/allocation/:reservationLinesId/:status',
  //   exact: true,
  //   name: 'Available Inventory',
  //   component: React.lazy(() => import('./views/AllocationDetail')),
  // },
];

export default routes;