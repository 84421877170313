/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/inventory/cyclecount',
    exact: true,
    name: 'Cycle Count',
    component: React.lazy(() => import('./views/ListCycleCount')),
  },
  {
    path: '/inventory/cyclecount/create',
    exact: true,
    name: 'Create',
    component: React.lazy(() => import('./views/CreateCycleCount')),
  },
  {
    path: '/inventory/cyclecount/detail/:headerCycleCountId',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdateCycleCount')),
  },
  // {
  //   path: '/receiving/update/skudetail/:skuProductId/:status',
  //   exact: true,
  //   name: 'SKU Detail',
  //   component: React.lazy(() => import('./views/DetailReceivingLines')),
  // },
  // {
  //   path: '/receiving/create/skudetail/:skuProductId',
  //   exact: true,
  //   name: 'SKU Detail',
  //   component: React.lazy(() => import('./views/DetailReceivingLines')),
  // },  
];

export default routes;