import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERWAREHOUSEENDPOINT,
  MASTERWAREHOUSEFETCH,
} from '../../config';
import {
  masterWarehouseSuccess,
  masterWarehouseFailed,
} from './ActionMasterWarhouse';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterWarehouse(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERWAREHOUSEENDPOINT, null, headers);

    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.warehouseCode} - ${item.warehouseName}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterWarehouseSuccess(result));
    } else {
      yield put(masterWarehouseFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterWarehouseFailed(error.message));
  }
}
 
export const watcherSagaMasterWarehouse = [
  takeLatest(MASTERWAREHOUSEFETCH, workerSagaMasterWarehouse),
];
