import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERGOODSSTATUSINVENTORYENDPOINT,
  MASTERGOODSSTATUSINVENTORYFETCH,
} from '../../config';
import {
  masterGoodsStatusInventorySuccess,
  masterGoodsStatusInventoryFailed,
} from '.';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterGoodsStatusInventory(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERGOODSSTATUSINVENTORYENDPOINT, null, headers);

    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.goodsStatusCode} - ${item.description}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterGoodsStatusInventorySuccess(result));
    } else {
      yield put(masterGoodsStatusInventoryFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterGoodsStatusInventoryFailed(error.message));
  }
}
 
export const watcherSagaMasterGoodsStatusInventory = [
  takeLatest(MASTERGOODSSTATUSINVENTORYFETCH, workerSagaMasterGoodsStatusInventory),
];
