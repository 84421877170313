import {
  Action,
  State,
} from '../../../../config/interfaces';

import {
  initialState,
  initialAction,
} from '../../../../config';

import {
  MASTERCUSTOMSDOCUMENTFETCH,
  MASTERCUSTOMSDOCUMENTSUCCESS,
  MASTERCUSTOMSDOCUMENTFAILED,
} from '../../config';

export function ReducerMasterCustomsDocument(state: State = initialState, action: Action = initialAction) {
  switch (action.type) {
    case MASTERCUSTOMSDOCUMENTFETCH:
      return {
        ...state,
        fetch: true,
        data: action.data,
        action: action.type,
      };

    case MASTERCUSTOMSDOCUMENTSUCCESS:
      return {
        ...state,
        fetch: false,
        res: action.data,
        action: action.type,
      };

    case MASTERCUSTOMSDOCUMENTFAILED:
      return {
        ...state,
        fetch: false,
        err: action.data,
        action: action.type,
      };

    default:
      return state; 
  }
}