export const RECEIVINGVALUE = 'RECEIVINGVALUE';
export const RECEIVINGENDPOINT = 'inbound/receivings';
export const PACKAGEDIMENSIONVALUE = 'PACKAGEDIMENSIONVALUE';

export const initialPage = {
  pagenumber: 0,
  direction: "DESC",
  order: 'createdDate',
  pageSize: 10,
};
export const ActionAlert: any = {
  Close: "Close",
  Correction: "Correction",
  Duplicate: "Duplicate",
  Save: "Save",
  Cancel: "Cancel",
  Send: "Send",
  Remove: "Remove",
  PostGL: "PostGL",
  Confirm: "Confirm",
  Rollback: "Rollback",
}

export const ReceivingType: any = {
  Exist: "Existing Asn",
  Blind: "Blind Receiving",
}

export const StatusAsn: any = {
  Closed: "Closed",
  Open: "Open",
  Proceed: "Proceed",
  Cancelled: "Cancelled",
  Received: "Received",
  PartialReceived: "Partial Received",
}

export const StatusReceiving = {
  Open: "Open",
  Confirm: "Confirmed",
  Cancelled: "Cancelled",
  Putaway: "Putaway",
}

export const deliveryTypes: any = [
  {
    id: 'Ocean',
    name: 'Ocean'
  },
  {
    id: 'Air',
    name: 'Air'
  },
  {
    id: 'Land',
    name: 'Land'
  },
  {
    id: 'Rail',
    name: 'Rail'
  },
];

export const defaultRequest = {
  totalItems: 0,
  currentPage: 0,
  pageSize: 0,
  totalPages: 0,
  startPage: 0,
  endPage: 0,
  startIndex: 0,
  endIndex: 0,
  pages: [],
  direction: '',
  orderBy: ''
};

export const defaultReceivingList = {
  content: [],
  first: 1,
  last: 0,
  page: 0,
  totalPages: 1,
  totalElements: 0,
  pageSize: 10,
  startPage: 0,
  endPage: 0,
  pages: [],
  direction: "ASC",
  orderBy: '',
};

export const defaultFilter = {
  asnDateFrom: '',
  asnDateTo: '',
  receiveDateFrom: '',
  receiveDateTo: '',
  ownerId: '',
  receiptNumber: '',
  asnDate: '',
  clientId: '',
  status: '',
  poNumber: '',
  blAwbNumber: '',
  ajuNumber: '',
  invoice: '',
};

export const defaultMasterType = {
  id: '',
  code: '',
  name: '',
  description: '',
  createdBy: '',
  createdDate: '',
  modifiedBy: '',
  lastUpdatedDate: '',
  activeFlag: '',
};

export interface DefaultCombobox {
  ownerWarehouses: any[];
  warehouses: any[];
  warehousesByOwner: any[];
  crossDockings: { id: string; code: string }[];
  customsDocuments: any[];
  customers: any[];
  customersbillto: [],
  suppliers: any[];
  currencies: any[];
  skuProducts: any[];
  skuProductsByOwner: any[];
  skuProductGroups: any[];
  uoms: any[];
  deliveryTypes: any[];
  status: any[];
  locations: any[];
  locationsAll: any[];
  locationsByWarehouse: any[];
  goodsStatus: any[];
  receivingStatus: any[];
  containerTypes: any[];
  packageTypes: any[];
}
export const defaultCombobox: DefaultCombobox = {
  ownerWarehouses: [],
  warehouses: [],
  warehousesByOwner: [],
  crossDockings: [
    { id: "", code: "Select" },
    { id: "Yes", code: "Yes" },
    { id: "No", code: "No" }],
  customsDocuments: [],
  customers: [],
  customersbillto: [],
  suppliers: [],
  currencies: [],
  skuProducts: [],
  skuProductsByOwner: [],
  skuProductGroups: [],
  uoms: [],
  deliveryTypes: [],
  status: [],
  locations: [],
  locationsAll: [],
  locationsByWarehouse: [],
  goodsStatus: [],
  receivingStatus: [],
  containerTypes: [],
  packageTypes: [],
};

export const defaultHeaderASN = {
  ownerId: '',
  ownerCode: '',
  plbType: false,
  crossDocking: '',
  receiptNumber: '',
  poNumber: '',
  poDate: '',
  othersReference: '',
  asnDate: '',
  deliveryOrder: '',
  contractNumber: '',
  blAwbNumber: '',
  deliveryType: '',
  receivingDate: '',
  receivingType: '',
  batch: '',
  vessel: '',
  voyageNumber: '',
  vesselEtaDate: '',
  origin: '',
  carrierCourier: '',
  portOfOrigin: '',
  vehicleNumber: '',
  cargoContainerNumber: '',
  cargoContainerTypeId: '',
  sealNumber: '',
  remark: '',
  customsDocumentId: '',
  ajuNumber: '',
  ajuDate: '',
  nopen: '',
  invoice: '',
  status: 'Open',
  previousAjuNumber: '',
  previousNopen: '',
  clientId: '',
  clientName: '',
  clientAddress: '',
  clientCity: '',
  clientCountry: '',
  supplierId: '',
  supplierName: '',
  supplierAddress: '',
  supplierCity: '',
  supplierCountry: '',
  attribute1: '',
  attribute2: '',
  attribute3: '',
  attribute4: '',
  attribute5: '',
  attribute6: '',
  receivinglines: [],
  receivingdocument: [],
};

export const parameterGrn: any = {
  receivingHeaderId: '',
  warehouseId: '',
  cargoContainerTypeId: '',
  customsDocumentId: '',
  receiptDate: '',
  receiptNumber: '',
  ajuNumber: '',
  ownerCode: '',
  nopen: '',
  clientName: '',
  poNumber: '',
  vehicleNumber: '',
  othersReference: '',
  cargoContainerNumber: '',
  deliveryOrder: '',
  deliveryType: '',
  sealNumber: '',
  blAwbNumber: '',
  batch: '',
}

export const saveFormat: any = {
  asnHeaderId: '',
  header: {},
  lines: [],
  linesGroup: [],
  documentTitle: [],
  uploadDocument: [],
  totalEvidence: 0,
  totalAsnDocument: 0,
};

export const saveFormatDetail: any = {
  lines: {},
  linesDetail: [],
};

export const defaultDocument = {
  documents: [
    {
      id: '',
      modules: '',
      title: '',
      fileName: '',
      originalName: '',
      createdBy: '',
      createdDate: '',
      file: {},
    }],
  newDocuments: [],
  deleteDocuments: [],
};

export const defaultFile = {
  id: '',
  modules: '',
  title: '',
  fileName: '',
  originalName: '',
  createdBy: '',
  createdDate: '',
  file: {},
};

export const defaultTitle = {
  id: 0,
  title: '',
};

export interface DataLineFormat {
  receivingLinesId: string;
  skuProductId: string;
  skuProductCode: string;
  generatedProductCode: string;
  skuProductDescription: string;
  warehouseId: string;
  expectedQuantity: number;
  skuProductWeight: number;
  skuProductVolume: number;
  receivedQuantity: number | "";
  receivingQuantity: string;
  isAllowMoreThan: boolean;
  isFromAsn: boolean;
  remainQuantity: number;
  uomId: string;
  uomCode: string;
  totalWeight: number;
  totalVolume: number;
  currencyId: string;
  valueOfGoods: number | "";
  totalValueOfGoods: number | "";
  packageNumber: string;
  packageTypeId: string;
  packageLength: number;
  packageWidth: number;
  packageHeight: number;
  packageWeight: number;
  packageVolume: number;
  serialNumber: string;
  serialNumber2: string;
  receiveStatusId: string;
  goodsStatusId: string;
  receivedLocationId: string;
  expiredDate: string;
  remarks: string;
  attribute1: string;
  attribute2: string;
  attribute3: string;
  attribute4: string;
  attribute5: string;
  attribute6: string;
  allocationPickingStrategy: string;
}
export const dataLineFormat: DataLineFormat = {
  receivingLinesId: "",
  skuProductId: "",
  skuProductCode: "",
  generatedProductCode: "",
  skuProductDescription: "",
  warehouseId: "",
  expectedQuantity: 0,
  skuProductWeight: 0,
  skuProductVolume: 0,
  receivedQuantity: "",
  receivingQuantity: "",
  isAllowMoreThan: false,
  isFromAsn: false,
  remainQuantity: 0,
  uomId: "",
  uomCode: "",
  totalWeight: 0,
  totalVolume: 0,
  currencyId: "",
  valueOfGoods: "",
  totalValueOfGoods: "",
  packageNumber: "",
  packageTypeId: "",
  packageLength: 0,
  packageWidth: 0,
  packageHeight: 0,
  packageWeight: 0,
  packageVolume: 0,
  serialNumber: "",
  serialNumber2: "",
  receiveStatusId: "",
  goodsStatusId: "",
  receivedLocationId: "",
  expiredDate: "",
  remarks: "",
  attribute1: "",
  attribute2: "",
  attribute3: "",
  attribute4: "",
  attribute5: "",
  attribute6: "",
  allocationPickingStrategy: "",
};

export const dataLineDetailFormat: any = {
  receivingLinesId: "",
  packageNumber: "",
  packageLength: 0,
  packageWidth: 0,
  packageHeight: 0,
  packageWeight: 0,
  packageVolume: 0,
  serialNumber: "",
  serialNumber2: "",
  receiveStatusId: "",
  receivingQuantity: 0,
  goodsStatusId: "",
  receivedLocationId: "",
  expiredDate: "",
};

export const packageDimensionFormat: any = {
  packageNumber: "",
  packageLength: 0,
  packageWidth: 0,
  packageHeight: 0,
  packageWeight: 0,
  packageVolume: 0,
};

export const defaultAsnFilterList: any = {
  asnNumbers: [],
  blAwbNumbers: [],
  poNumbers: [],
  invoices: [],
  ajuNumbers: [],
}

export const defaultOptionCreate: any = {
  selectedOption: 'Automate',
  asnNumber: '',
  poNumber: '',
  blAwbNumber: '',
  ajuNumber: '',
}

export const StatusFilter: any = [
  { id: "",
    name: "Select",
  },
  {
    id: "Open",
    name: "Open",
  },
  {
    id: "Confirmed",
    name: "Confirmed",
  },
  {
    id: "Putaway",
    name: "Putaway",
  },
  {
    id: "Cancelled",
    name: "Cancelled",
  },
];

export const defaultReceivingFilterList: any = {
  asnNumbers: [],
  blAwbNumbers: [],
  poNumbers: [],
  invoices: [],
  ajuNumbers: [],
}

export const showField: any = {
  othersRef: false,
  blAwbNumber: false,
  ajuNumber: false,
  nopen: false,
  crossDocking: false,
  invoice: false,
}

export const tableHeader: any = [
  {
    id:"othersRef",
    name:"Others Reference",
  },
  {
    id:"blAwbNumber",
    name:"BL/AWB Number",
  },
  {
    id:"ajuNumber",
    name:"AJU Number",
  },
  {
    id:"nopen",
    name:"Nopen",
  },
  {
    id:"crossDocking",
    name:"Cross Docking",
  },
  {
    id:"invoice",
    name:"Invoice",
  },
];

