import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERGOODSSTATUSENDPOINT,
  MASTERGOODSSTATUSFETCH,
} from '../../config';
import {
  masterGoodsStatusSuccess,
  masterGoodsStatusFailed,
} from '.';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterGoodsStatus(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERGOODSSTATUSENDPOINT, null, headers);

    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.goodsStatusCode} - ${item.description}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterGoodsStatusSuccess(result));
    } else {
      yield put(masterGoodsStatusFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterGoodsStatusFailed(error.message));
  }
}
 
export const watcherSagaMasterGoodsStatus = [
  takeLatest(MASTERGOODSSTATUSFETCH, workerSagaMasterGoodsStatus),
];
