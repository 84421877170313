/**
 * @author: dwi.setiyadi@gmail.com
*/

/*
Startup Examples:

import { watcherModules } from '../modules/some/SagaSome';
export default [
  ...watcherModules,
];
*/

//import { watcherNowPlaying } from '../modules/dashboard/SagaDashboard';
import {
  watcherSagaMasterType,
  watcherSagaMasterContainerType,
  watcherSagaMasterCurrency,
  watcherSagaMasterCustomsDocument,
  watcherSagaMasterGoodsStatus,
  watcherSagaMasterLocation,
  watcherSagaMasterLocationProject,
  watcherSagaMasterOwner,
  watcherSagaMasterZone,
  watcherSagaMasterStorage,
  watcherSagaMasterReceivingStatus,
  watcherSagaMasterSkuProduct,
  watcherSagaMasterSupplier,
  watcherSagaMasterUom,
  watcherSagaMasterWarehouse,
  watcherSagaMasterCustomer,
  watcherSagaMasterPackageType,
  watcherSagaMasterSkuProductGroup,
  watcherSagaMasterCustomerbillto,
} from '../modules/foundation/redux';
import { watcherSagaLogin } from '../modules/auth/redux';
export default [
  //...watcherNowPlaying,
  ...watcherSagaLogin,
  ...watcherSagaMasterType,
  ...watcherSagaMasterWarehouse,
  ...watcherSagaMasterContainerType,
  ...watcherSagaMasterUom,
  ...watcherSagaMasterSupplier,
  ...watcherSagaMasterSkuProduct,
  ...watcherSagaMasterReceivingStatus,
  ...watcherSagaMasterOwner,
  ...watcherSagaMasterZone,
  ...watcherSagaMasterStorage,
  ...watcherSagaMasterLocation,
  ...watcherSagaMasterLocationProject,
  ...watcherSagaMasterGoodsStatus,
  ...watcherSagaMasterCustomsDocument,
  ...watcherSagaMasterCurrency,
  ...watcherSagaMasterCustomer,
  ...watcherSagaMasterPackageType,
  ...watcherSagaMasterSkuProductGroup,
  ...watcherSagaMasterCustomerbillto,
];
