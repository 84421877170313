import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERCONTAINERTYPEFETCH,
  MASTERCONTAINERTYPEENDPOINT,
} from '../../config';
import {
  masterContainerTypeSuccess,
  masterContainerTypeFailed,
} from '.';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterContainerType(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERCONTAINERTYPEENDPOINT, null, headers);

    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.containertypeCode} - ${item.containertypeName}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterContainerTypeSuccess(result));
    } else {
      yield put(masterContainerTypeFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterContainerTypeFailed(error.message));
  }
}
 
export const watcherSagaMasterContainerType = [
  takeLatest(MASTERCONTAINERTYPEFETCH, workerSagaMasterContainerType),
];
