/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/inventoryadjustment',
    exact: true,
    name: 'Inventory Adjustment',
    component: React.lazy(() => import('./views/ListInventoryAdjustment')),
  },
  {
    path: '/inventoryadjustment/create',
    exact: true,
    name: 'Create',
    component: React.lazy(() => import('./views/CreateInventoryAdjustment')),
  },
  {
    path: '/inventoryadjustment/update/:inventoryAdjustmentId',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdateInventoryAdjustment')),
  },
];

export default routes;