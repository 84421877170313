import { AccountInfo, AuthenticationResult, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { PublicStore, SET_JWT_TOKEN, Store } from "../bootstrap/ReduxStore";
import { APIPORT, APIPROTOCOL, LANDINGHOST, REDIRECT_URI, CLIENT_ID_B2C } from "../config/env";
import { userLoginSuccess } from "../modules/auth/redux";
import { AuthConfig } from "./AuthConfig";
import { HttpService } from "./HttpService";

const USER_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL'
}

class AuthService {
  pcaClient: PublicClientApplication;
  b2cClient: PublicClientApplication;
  loginRequestInternal: RedirectRequest;
  loginRequestExternal: RedirectRequest;
  account: AccountInfo | null;

  constructor() {
      let config = new AuthConfig()
      this.pcaClient = new PublicClientApplication(config.pcaConfig);
      this.b2cClient = new PublicClientApplication(config.b2cConfig);

      this.loginRequestInternal = {
          scopes: ["openid", "profile"],
          redirectUri: REDIRECT_URI,
      };
      this.loginRequestExternal = {
          scopes: ["openid", "profile", CLIENT_ID_B2C],
          redirectUri: REDIRECT_URI,
      };
      this.account = null;
  }

  loadAuthModule(): void {
    this.pcaClient
      .handleRedirectPromise()
      .then((resp: AuthenticationResult | null) => {
          if (resp) {
              this.handleResponsePCA(resp);
          }
      })
      .catch(console.error);

    this.b2cClient
      .handleRedirectPromise()
      .then((resp: AuthenticationResult | null) => {
          if (resp) {
              this.handleResponseB2C(resp);
          }
      })
      .catch(console.error);
  }

  async handleResponsePCA(response: AuthenticationResult) {
    if (response !== null) {
      localStorage.setItem('userType', USER_TYPE.INTERNAL)
      PublicStore.dispatch({type: SET_JWT_TOKEN, data: response.idToken})
      let userinfo = await this.getUserInfo(response)
      let finalToken = {
        ...response,
        userinfo: userinfo.data.message.user,
        token: response.idToken,
        username: response.account?.username,
        roles: userinfo.data.message.roles,
        locations: userinfo.data.message.locations,
        owners: userinfo.data.message.owners,
        exp: response.expiresOn?.getTime()
      }
      localStorage.setItem('userinfo', JSON.stringify(finalToken))
      Store.dispatch(userLoginSuccess(finalToken))
    }else{
      console.log('test')
    }
  }

  async handleResponseB2C(response: AuthenticationResult) {
    if (response !== null) {
      let temp: any = response.idTokenClaims;
      let accountTemp: any = {...response.account, username: temp.email}
      localStorage.setItem('userType', USER_TYPE.EXTERNAL)
      PublicStore.dispatch({type: SET_JWT_TOKEN, data: response.idToken})
      let userinfo = await this.getUserInfo({...response, account: accountTemp})
      let finalToken = {
        ...response,
        userinfo: userinfo.data.message.user,
        token: response.idToken,
        username: userinfo.data.message.user.Email,
        roles: userinfo.data.message.roles,
        locations: userinfo.data.message.locations,
        owners: userinfo.data.message.owners,
        exp: response.expiresOn?.getTime()
      }
      localStorage.setItem("userinfo", JSON.stringify(finalToken));
      Store.dispatch(userLoginSuccess(finalToken))
    }else{
      console.log('test')
    }
  }

  signInInternal(username: string): void {
    this.pcaClient.loginRedirect({...this.loginRequestInternal, loginHint: username});
  }

  signInB2C(username: string): void {
    this.b2cClient.loginRedirect({...this.loginRequestExternal, loginHint: username});
  }

  async signOut(): Promise<void> {
    let userType = localStorage.getItem('userType');
    if(userType === USER_TYPE.INTERNAL){
      localStorage.clear();
      await this.pcaClient.logout();
    }else{
      localStorage.clear();
      await this.b2cClient.logout();
    }
  }

  async getTokenSilent(): Promise<AuthenticationResult | void>{
    try{
      let userType = localStorage.getItem('userType')
      if(userType === USER_TYPE.INTERNAL){
        let accounts = this.pcaClient.getAllAccounts()
        let token = await this.pcaClient.acquireTokenSilent({...this.loginRequestInternal, account: accounts[0]})

        return token
      }else if(userType === USER_TYPE.EXTERNAL){
        let accounts = this.b2cClient.getAllAccounts()
        let token = await this.b2cClient.acquireTokenSilent({...this.loginRequestExternal, account: accounts[0]})

        return token
      }else{
        return undefined
      }
    }catch(err){
      return undefined
    }
  }

  async getUserInfo(authResult: AuthenticationResult){
    const headers = {
      'Token-Auth': authResult.idToken,
    }
    let body = {
      Action: 'READ_USER',
      User: {
          Email: authResult.account?.username,
          sub: authResult.uniqueId
      },
      ApplicationCode: 'WM',
    };

    let result: any = await HttpService.post('authentication/userinfo', body, headers);
    return result;
  }

  getAccount(): AccountInfo[]{
    let userType = localStorage.getItem('userType')
    if(userType === USER_TYPE.INTERNAL){
      return this.pcaClient.getAllAccounts()
    }else{
      return this.b2cClient.getAllAccounts()
    }
  }

  onSessionExpired(){
    localStorage.clear();
    const apiport = (APIPORT === '80') ? '' : `:${APIPORT}`;
    const path = `${APIPROTOCOL}://${LANDINGHOST}${apiport}`;
    window.location.assign(`${path}/#/login`);
  }
}

const authService = new AuthService();

export default authService;
