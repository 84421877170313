import { Action, State } from '../../../config/interfaces';

import {
  initialAction,
  initialState,
} from '../../../config'

import {
  DISASSEMBLYCHANGE,
  DISASSEMBLYBYOWNER,
  DISASSEMBLYLINES,
  DISASSEMBLYDETAIL,
  DISASSEMBLYALLOCATE,
  BOMHEADER,
} from '../config/constant';

export function ReducerDisassembly(state: State = initialState, action: Action = initialAction) {
  switch (action.type) {
    case DISASSEMBLYCHANGE:
      return {
        ...state,
        data: action.data,
      };

      case DISASSEMBLYBYOWNER:
        return {
          ...state,
          disassemblyByOwner : {
            warehouseByOwner: action.data.warehouseByOwner,
            locationByOwner: action.data.locationByOwner,
            productByOwner: action.data.productByOwner,
            ajuNumber: action.data.ajuNumber,
          }
        };

      case DISASSEMBLYLINES:
        return {
          ...state,
          disassemblyLines: action.data.bomLines,
        };
      
      case DISASSEMBLYDETAIL:
        return {
          ...state,
          disassemblyDetail: action.data.disassemblyDetail,
        };

      case BOMHEADER:
        return{
          ...state,
          bomHeader: action.data.bomHeader,
        };
      case DISASSEMBLYALLOCATE:
        return {
          ...state,
          disassemblyAllocate: action.data.disassemblyAllocate,
        }
    default:
      return state;
  }
}
