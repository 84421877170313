import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERCUSTOMERENDPOINT,
  MASTERCUSTOMERFETCH,
} from '../../config';
import {
  masterCustomerSuccess,
  masterCustomerFailed,
} from '.';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterCustomer(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERCUSTOMERENDPOINT, null, headers);

    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.customerCode} - ${item.customerName}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterCustomerSuccess(result));
    } else {
      yield put(masterCustomerFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterCustomerFailed(error.message));
  }
}
 
export const watcherSagaMasterCustomer = [
  takeLatest(MASTERCUSTOMERFETCH, workerSagaMasterCustomer),
];
