export const UserAPI = {
  login: {
    api: 'authentication/signin',
    authorize: 'authentication/authorize',
    isPublicApi: true,
  },
  register: {
    api: 'signup',
    isPublicApi: true,
  },
};
