import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from '../../../../config/interfaces';
import {
  MASTERTYPEENDPOINT,
  MASTERTYPEFETCH,
} from '../../config';
import {
  masterTypeSuccess,
  masterTypeFailed,
} from './ActionMasterType';
import { HttpService } from '../../../../utilities';

function* workerSagaMasterType(params: Action) {
  try {
    const headers = {
      ...params.data.headers,
    };

    const response = yield call(HttpService.get, MASTERTYPEENDPOINT, null, headers);

    if (!response.data) throw(response);
    if (response.status === 200 && response.data) {
      const result: object[] = [];
      response.data.message.masterTypes.forEach((item: any) => {
        let data = {
          ...item,
          codeName: `${item.code} - ${item.name}`
        }

        result.push(data);
        data = {};
      });
      yield put(masterTypeSuccess(result));
    } else {
      yield put(masterTypeFailed(response.data.res));
    }
  } catch (error) {
    yield put(masterTypeFailed(error.message));
  }
}
 
export const watcherSagaMasterType = [
  takeLatest(MASTERTYPEFETCH, workerSagaMasterType),
];
