/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/packing',
    exact: true,
    name: 'Packing',
    component: React.lazy(() => import('./views/ListPacking')),
  },
  {
    path: '/packing/create',
    exact: true,
    name: 'Add Packing',
    component: React.lazy(() => import('./views/CreatePacking')),
  },
  {
    path: '/packing/detail/:packingId',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdatePacking')),
  },
  {
    path: '/view/single/:packageNumber',
    exact: true,
    name: 'View QR Code Single',
    component: React.lazy(() => import('./views/ViewQrCodeSingle')),
  },
  {
    path: '/view/multiple/:reservationNumber',
    exact: true,
    name: 'View QR Code Multiple',
    component: React.lazy(() => import('./views/ViewQrCodeMultiple')),
  },
];

export default routes;