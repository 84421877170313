import moment from 'moment';
import configure from 'react-widgets/lib/configure';

if (typeof moment !== 'function')
  throw new TypeError('You must provide a valid moment object')

// var localField = typeof moment().locale === 'function' ? 'locale' : 'lang'
 var hasLocaleData = !!moment.localeData;

if (!hasLocaleData)
  throw new TypeError(
    'The Moment localizer depends on the `localeData` api, please provide a moment object v2.2.0 or higher')

function getMoment(culture, value, format){
  let timeMoment = moment.utc(value, format, true);
  return timeMoment;
}

function getMoment2(culture, value, format){
  let timeMoment = moment.utc(value, format, true).add(new Date().getTimezoneOffset()/-60, "hours");
  return timeMoment;
}

function endOfDecade(date) {
  return moment.utc(date).add(10, 'year').add(-1, 'millisecond').toDate()
}

function endOfCentury(date) {
  return moment.utc(date).add(100, 'year').add(-1, 'millisecond').toDate()
}

export function momentLocalizer() {
  let localizer = {
    formats: {
      date: 'L',
      time: 'LT',
      default: 'lll',
      header: 'MMMM YYYY',
      footer: 'LL',
      weekday: 'dd',
      dayOfMonth: 'DD',
      month: 'MMM',
      year: 'YYYY',

      decade(date, culture, localizer) {
        return localizer.format(date, 'YYYY', culture)
          + ' - ' + localizer.format(endOfDecade(date), 'YYYY', culture)
      },

      century(date, culture, localizer) {
        return localizer.format(date, 'YYYY', culture)
          + ' - ' + localizer.format(endOfCentury(date), 'YYYY', culture)
      }
    },

    firstOfWeek(culture) {
      return moment.localeData(culture).firstDayOfWeek()
    },

    parse(value, format, culture) {
      if (!value) return null;
      const m = getMoment(culture, value, format);

      if (m.isValid()){
        let date = m.toDate();
        return date;
      }
      return null;
    },

    format(value, format, culture) {
      let form = getMoment(culture, value, format).format(format);
      return form;
    }
  }

  configure.setDateLocalizer(localizer)
}

export function momentLocalizer1() {
  let localizer = {
    formats: {
      date: 'L',
      time: 'LT',
      default: 'lll',
      header: 'MMMM YYYY',
      footer: 'LL',
      weekday: 'dd',
      dayOfMonth: 'DD',
      month: 'MMM',
      year: 'YYYY',

      decade(date, culture, localizer) {
        return localizer.format(date, 'YYYY', culture)
          + ' - ' + localizer.format(endOfDecade(date), 'YYYY', culture)
      },

      century(date, culture, localizer) {
        return localizer.format(date, 'YYYY', culture)
          + ' - ' + localizer.format(endOfCentury(date), 'YYYY', culture)
      }
    },

    firstOfWeek(culture) {
      return moment.localeData(culture).firstDayOfWeek()
    },

    parse(value, format, culture) {
      if (!value) return null;
      const m = getMoment2(culture, value, format);

      if (m.isValid()){
        let date = m.toDate();
        return date;
      }
      return null;
    },

    format(value, format, culture) {
      let form = getMoment(culture, value, format).format(format);
      return form;
    }
  }

  configure.setDateLocalizer(localizer)
}

export function momentLocalizer2() {
  let localizer = {
    formats: {
      date: 'L',
      time: 'LT',
      default: 'lll',
      header: 'MMMM YYYY',
      footer: 'LL',
      weekday: 'dd',
      dayOfMonth: 'DD',
      month: 'MMM',
      year: 'YYYY',

      decade(date, culture, localizer) {
        return localizer.format(date, 'YYYY', culture)
          + ' - ' + localizer.format(endOfDecade(date), 'YYYY', culture)
      },

      century(date, culture, localizer) {
        return localizer.format(date, 'YYYY', culture)
          + ' - ' + localizer.format(endOfCentury(date), 'YYYY', culture)
      }
    },

    firstOfWeek(culture) {
      return moment.localeData(culture).firstDayOfWeek()
    },

    parse(value, format, culture) {
      if (!value) return null;
      const m = getMoment(culture, value, format);

      if (m.isValid()){
        let date = m.toDate();
        return date;
      }
      return null;
    },

    format(value, format, culture) {
      let form = getMoment2(culture, value, format).format(format);
      return form;
    }
  }

  configure.setDateLocalizer(localizer)
}

export function momentLocalizer3() {
  let localizer = {
    formats: {
      date: 'L',
      time: 'LT',
      default: 'lll',
      header: 'MMMM YYYY',
      footer: 'LL',
      weekday: 'dd',
      dayOfMonth: 'DD',
      month: 'MMM',
      year: 'YYYY',

      decade(date, culture, localizer) {
        return localizer.format(date, 'YYYY', culture)
          + ' - ' + localizer.format(endOfDecade(date), 'YYYY', culture)
      },

      century(date, culture, localizer) {
        return localizer.format(date, 'YYYY', culture)
          + ' - ' + localizer.format(endOfCentury(date), 'YYYY', culture)
      }
    },

    firstOfWeek(culture) {
      return moment.localeData(culture).firstDayOfWeek()
    },

    parse(value, format, culture) {
      if (!value) return null;
      const m = getMoment2(culture, value, format);

      if (m.isValid()){
        let date = m.toDate();
        return date;
      }
      return null;
    },

    format(value, format, culture) {
      let form = getMoment2(culture, value, format).format(format);
      return form;
    }
  }

  configure.setDateLocalizer(localizer)
}