const customStylesSelect = {
  control: (provided: any) => ({
    ...provided,
    maxHeight: '150px',
    minHeight: '38px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    minHeight: '30px',
    maxHeight: '140px',
    padding: '0 6px',
    overflowY: 'scroll',
  }),

  input: (provided: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: ()=> ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    minHeight: '38px',
  }),
};

export default customStylesSelect;
