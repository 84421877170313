import {
  Action,
  State,
} from '../../../../config/interfaces';

import {
  initialState,
  initialAction,
} from '../../../../config';

import {
  MASTERTYPEFETCH,
  MASTERTYPESUCCESS,
  MASTERTYPEFAILED,
} from '../../config';

export function ReducerMasterType(state: State = initialState, action: Action = initialAction) {
  switch (action.type) {
    case MASTERTYPEFETCH:
      return {
        ...state,
        fetch: true,
        data: action.data,
        action: action.type,
      };

    case MASTERTYPESUCCESS:
      return {
        ...state,
        fetch: false,
        res: action.data,
        action: action.type,
      };

    case MASTERTYPEFAILED:
      return {
        ...state,
        fetch: false,
        err: action.data,
        action: action.type,
      };

    default:
      return state; 
  }
}