import { Action, State } from '../../../config/interfaces';

import {
  initialAction,
  initialState,
} from '../../../config'

import {
  PLANNINGVALUE,
} from '../config/constant';

export function ReducerPlanning(state: State = initialState, action: Action = initialAction) {
  switch (action.type) {
    case PLANNINGVALUE:
      return {
        ...state,
        fetch: true,
        data: action.data,
      };

    default:
      return state;
  }
}
