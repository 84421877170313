/**
 * @author: dwi.setiyadi@gmail.com
*/

import React from 'react';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/receiving',
    exact: true,
    name: 'Receiving',
    component: React.lazy(() => import('./views/ListReceiving')),
  },
  {
    path: '/pdfview/:receivingId/:printQty',
    exact: true,
    name: 'View QR Code',
    component: React.lazy(() => import('./views/ViewerPDF')),
  },
  {
    path: '/receiving/create/:status',
    exact: true,
    name: 'Create',
    component: React.lazy(() => import('./views/CreateReceiving')),
  },
  {
    path: '/receiving/update/:receivingId/:status',
    exact: true,
    name: 'Update',
    component: React.lazy(() => import('./views/UpdateReceiving')),
  },
  {
    path: '/receiving/update/skudetail/:skuProductId/:status',
    exact: true,
    name: 'SKU Detail',
    component: React.lazy(() => import('./views/DetailReceivingLines')),
  },
  {
    path: '/receiving/create/skudetail/:skuProductId',
    exact: true,
    name: 'SKU Detail',
    component: React.lazy(() => import('./views/DetailReceivingLines')),
  },  
];

export default routes;