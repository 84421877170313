const defaultStyleProduct = {
  valueContainer: () => ({
    padding: "10px",
    color: "rgba(33, 33, 33, 0.5)",
    fontSize: "14px",
  }),
  control: (base: any) => ({
    ...base,
    borderStyle: "none",
    borderRadius: "8px"
  }),
  menu: (base: any) => ({
    ...base,
    borderStyle: "none",
    borderRadius: "8px",
    width: "max-content",
  }),
  input: () => ({
    color: "rgba(33, 33, 33, 0.5)",
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "rgba(33, 33, 33, 0.5)",
  }),
  multiValue: (base: any) => ({
    ...base,
    color: "rgba(33, 33, 33, 0.5)",
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "rgba(33, 33, 33, 0.5)",
    fontSize: "14px",
  }),
  option: (styles: any) => ({
    ...styles,
    textAlign: 'left',
    zIndex: 1,
    color: "rgba(33, 33, 33, 0.5)",
    borderStyle: "none",

    ':active': {
      ...styles[':active'],
      backgroundColor: "rgba(120, 189, 196, 0.5)",
    },
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
    color: "rgba(33, 33, 33, 0.5)",
    borderStyle: "none",
  }),
  indicatorSeparator: () => ({ opacity: 0 }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "black",
  }),
};

export default defaultStyleProduct;
