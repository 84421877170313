import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { APP_INSIGHT_KEY } from "../config/env";

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: APP_INSIGHT_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
var telemetryInitializer = (envelope) => {
  envelope.tags["ai.cloud.role"] = "lintas-wm-web";
}
appInsights.addTelemetryInitializer(telemetryInitializer);
export { reactPlugin, appInsights };